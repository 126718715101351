import { Component, ElementRef, ViewChild, Input } from "@angular/core";
import { Registration } from "../../../../models/registration";

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.css"],
})
export class ImageUploadComponent {
  @Input() registration: Registration;

  sending: boolean = false; /* Flag that data sending is in progress */

  imageDescription: string = "";
  imageFileName: string | any;
  imageFileType: string | any;
  imageFileValue: string | any;

  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;

  ngOnInit() {}

  constructor() {}

  /**
   * Read and store image file data from user input
   * @param event
   */
  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];

      reader.readAsDataURL(
        file
      ); /** Read the contents of the specified File. */

      reader.onloadend = () => {
        this.imageFileName = file.name;
        this.imageFileType = file.type;
        this.imageFileValue = (reader.result as string).split(
          ","
        )[1]; /** Actual base64 encoded image data is after the comma */
      };
    }
  }

  onSubmit() {
    this.sendImageDataToBackend();
  }

  /**
   * Send data [image description, image file data, registration edit token] to the backend; time out if data sending is taking too long.
   */
  sendImageDataToBackend() {
    this.sending = true;

    /* Add http request here: this.http.post('apiEndpointUrl', formModel) */

    setTimeout(() => {
      alert("Timed out uploading image!");
      this.sending = false;
    }, 4000);
  }

  /**
   * Clear user inputs
   */
  clearInputs() {
    this.imageDescription = "";
    this.imageFileName = null;
    this.imageFileType = null;
    this.imageFileValue = null;
    this.sending = false;
    this.fileInput.nativeElement.value = "";
  }
}
