import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ModelService } from "./model.service";
import { Observable } from "rxjs";
import { Ce_credit } from "../models/ce_credit";
import { environment } from "../../environments/environment";

@Injectable()
export class CeCreditService extends ModelService<Ce_credit> {
  private url = environment.apiUrl + "ce";

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   *
   * GETS seminar attendants from server
   *
   * @param first_name
   * @param last_name
   * @returns {Observable<Object>}
   */
  getCeCredits(first_name: string, last_name: string): Observable<Ce_credit[]> {
    const url = this.url + "/search/" + first_name + "/" + last_name;
    return this.get(url);
  }

  /**
   * Returns PDF data to download for client
   * @returns {Observable<Blob>}
   */
  downloadCeCredits(attendant: Ce_credit): Observable<any> {
    const url = this.url + "/download" + this.buildUrlParamsString(attendant);
    return this.downloadCeCredit(url);
  }

  protected buildUrlParamsString(attendant: Ce_credit): string {
    let params = "";

    for (let field in attendant) {
      params += "/" + encodeURIComponent(attendant[field]);
    }

    return params;
  }
}
