import { map, switchMap, retry, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import "rxjs/add/operator/retry";
import "rxjs/add/operator/switchMap";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/xml" }),
};

@Injectable()
export abstract class ModelService<T> {
  constructor(protected http: HttpClient) {}

  /**
   * GET Seminars from server only where country matches
   * this may actually be changed to accept a country id and ! a string
   * @param params
   * @param url
   * @returns {Observable<Object>}
   */
  //todo: MARGARITA TAKE NOTE, YOU MAY NEED TO ALTER THE GENERIC RETURN TYPES HERE AS SOME THINGS EXPECT
  //todo: A GENERIC OBSERVABLE AND OTHERS NOT
  get(url: string, params?: {}): Observable<any> {
    let token = localStorage.getItem("tok");
    const headers = new HttpHeaders({ Authorization: token || "None" });

    return this.authenticate().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: token["token"] || "None",
        });
        return this.httpInterceptor(
          this.http.get<any>(url, {
            headers: headers,
            params: params,
          })
        );
      })
    );
  }

  /**
   *
   * @param url
   * @param payload
   * @returns {any}
   */
  post(url: string, payload?: {}): Observable<any> {
    return this.authenticate().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: token["token"] || "None",
        });
        return this.httpInterceptor(
          this.http.post(url, payload, {
            headers: headers,
          })
        );
      })
    );
  }

  /**
   *
   * @param url
   * @param resource
   * @returns {any}
   */
  put(url: string, resource?: {}): Observable<any> {
    return this.authenticate().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: token["token"] || "None",
        });
        return this.httpInterceptor(
          this.http.put(url, resource, {
            headers: headers,
          })
        );
      })
    );
  }

  delete(url: string): Observable<any> {
    return this.authenticate().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: token["token"] || "None",
        });
        return this.httpInterceptor(
          this.http.delete(url, {
            headers: headers,
          })
        );
      })
    );
  }

  /**
   * @param url - url to post to
   * @param payload - payment data or (payment data + extra licensing agreement details).
   * @returns {any}
   */
  postPaymentPayload(url: string, payload?: {}): Observable<any> {
    return this.authenticate().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: token["token"] || "None",
        });
        return this.http.post<any>(url, payload, {
          headers: headers,
        });
      })
    );
  }

  downloadCeCredit(url: string): Observable<any> {
    return this.authenticate().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: token["token"] || "None",
        });
        return this.http.get(url, { responseType: "blob", headers: headers });
      })
    );
  }

  getPublications(url: string): Observable<any> {
    return this.authenticate().pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: token["token"] || "None",
        });
        return this.http.get<any>(url, { headers: headers });
      })
    );
  }

  private authenticate(): Observable<any> {
    return this.http.post(environment.apiUrl + "/authenticate", {
      traffic: "cqnlxj.7j6dsl04glf7jgfs8vq",
      lane: "0zuu5li0g1fny3fr7hu9kb15mspflhmco8vqr",
    });
  }

  private httpInterceptor(observable$: Observable<any>): Observable<any> {
    return observable$.pipe(
      catchError((err, source) => {
        if (err.status == 401) {
          return this.authenticate().pipe(
            map((data) => {
              return observable$.pipe(retry(3));
            })
          );
        } else {
          throw err; /* handle normal errors */
        }
      })
    );
  }
}
