import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ModelService } from "./model.service";

@Injectable()
export class PaymentService extends ModelService<Payment> {
  private paymentUrl = environment.apiUrl + "payment";

  constructor(http: HttpClient) {
    super(http);
  }

  sendPayment(payment: Payment): Observable<any> {
    return this.postPaymentPayload(this.paymentUrl, payment);
  }
}

//again just to force type safety
interface Payment {
  name: string;
  token: string;
  email: string;
  ip: string;
  amount: number;
  registrationId: number;
}
