import { Component, OnInit, Input } from "@angular/core";
import { Provider } from "../../../models/provider";

@Component({
  selector: "search-app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class SearchMapComponent implements OnInit {
  @Input() providers: Provider[];
  @Input() lat: number;
  @Input() lng: number;
  constructor() {}

  ngOnInit() {}
}
