export class FastResultsItemModel {
  visible: boolean = false;
  description: string = "";
  day: number = 0;
  imageSrc: string = "";
  visibleAtMinY: number = 0;
  visibleAtMaxY: number = 0;
  marginLeft: number = 0;
  marginTop: number = 0;

  constructor(description: string, day: number, imageSrc: string) {
    this.description = description;
    this.day = day;
    this.imageSrc = imageSrc;
  }
}
