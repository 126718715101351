import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements OnInit {
  responsiveOptions;
  constructor() {
    this.responsiveOptions = [
      {
        breakpoint: "1400px",
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: "900px",
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
        numScroll: 1,
      },
    ];
  }
  ngOnInit(): void {}

  openPublicationLink(url): void {
    window.open(url, "_blank");
  }

  publication: Array<{ title: string; publishDate: string; link: string }> = [
    {
      title: "Prediction of orthodontic treatment duration based on the alveolar bone formula",
      publishDate: "December 6, 2023",
      link: "/assets/articles/JDHODT-14-00607.pdf",
    },
    {
      title: "Favorable treatment outcomes and shorter durations: a retrospective case series on seven patients using Orthoworld FASTBRACES®",
      publishDate: "September 4, 2023",
      link: "/assets/articles/JDHODT-14-00601.pdf",
    },
    {
      title: "Orthodontic treatment and patient satisfaction with Orthoworld Fastbraces® in pediatric population",
      publishDate: "July 31, 2023",
      link: "/assets/articles/JDHODT-14-00598.pdf",
    },
     {
      title: "Orthodontic treatment with Orthoworld Fastbraces®: a retrospective analysis of treatment duration, outcomes, and patient satisfaction",
      publishDate: "July 11, 2023",
      link: "/assets/articles/JDHODT-14-00596.pdf",
    },
    {
      // title: "A new orthodontic diagnosis based on the alveolar bone- the 3rd hard tissue of dentistry",
      title: "A new orthodontic diagnosis based on the cupping depth of the alveolar bone",
      publishDate: "June 23, 2023",
      // link: "https://medcraveonline.com/JDHODT/JDHODT-14-00595.pdf",
      link: "/assets/articles/JDHODT-14-00595.pdf",
    },
    {
      title: "New dental hygiene protocol for systemic disease prevention",
      publishDate: "October 03, 2019",
      // link: "https://medcraveonline.com/JDHODT/JDHODT-10-00500.pdf",
      link: "/assets/articles/JDHODT-10-00500.pdf",
    },
    {
      title:
        "Orthodontitis: the distinct gingivitis around malpositioned teeth",
      publishDate: "April 16, 2019",
      // link: "https://medcraveonline.com/JDHODT/JDHODT-10-00479.pdf",
      link: "/assets/articles/JDHODT-10-00479.pdf",
    },
    {
      title: "The Viazis Classification of Malocclusion",
      publishDate: "September 28, 2018",
      // link: "https://medcraveonline.com/JDHODT/JDHODT-09-00410.pdf",
      link: "/assets/articles/JDHODT-09-00410.pdf",
    },
    {
      title:
        "Orthodontic diagnosis based upon alveolar bone clinical morphology",
      publishDate: "December 27, 2017",
      // link: "http://medcraveonline.com/JDHODT/JDHODT-08-00316.pdf",
      link: "/assets/articles/JDHODT-08-00316.pdf",
    },
    {
      title: "The biology of orthodontic treatment time; days versus years",
      publishDate: "August 17, 2017",
      // link: "http://medcraveonline.com/JDHODT/JDHODT-08-00268.pdf",
      link: "/assets/articles/JDHODT-08-00268.pdf",
    },
    {
      title: "Alveolar bone growth with orthoeruption",
      publishDate: "July 25, 2017",
      // link: "http://medcraveonline.com/JDHODT/JDHODT-07-00258.pdf",
      link: "/assets/articles/JDHODT-07-00258.pdf",
    },
    {
      title:
        "Alveolar bone remodeling and development after immediate orthodontic root movement",
      publishDate: "Janurary 31, 2017",
      // link: "http://medcraveonline.com/JDHODT/JDHODT-06-00195.pdf",
      link: "/assets/articles/JDHODT-06-00195.pdf",
    },
    {
      title:
        "Non-surgical orthodontic adult molar crossbite correction and sleep apnea",
      publishDate: "December 01, 2016",
      // link: "http://medcraveonline.com/JDHODT/JDHODT-05-00168.pdf",
      link: "/assets/articles/JDHODT-05-00168.pdf",
    },
    {
      title:
        "Non-surgical orthodontic treatment of an orthognathic surgical case",
      publishDate: "July 5, 2016",
      // link: "http://medcraveonline.com/JDHODT/JDHODT-04-00128.pdf",
      link: "/assets/articles/JDHODT-04-00128.pdf",
    },
    {
      title:
        "The fallacy of tongue thrust and non-surgical treatment of a severe anterior open bite",
      publishDate: "April 20, 2016",
      // link: "http://medcraveonline.com/JDHODT/JDHODT-04-00120.pdf",
      link: "/assets/articles/JDHODT-04-00120.pdf",
    },
    {
      title:
        "The concept of a new dental disease: orthodontosis and orthodontitis",
      publishDate: "September 30, 2014",
      // link: "http://medcraveonline.com/JDHODT/JDHODT-01-00030.pdf",
      link: "/assets/articles/JDHODT-01-00030.pdf",
    },
    {
      title: "The five types of alveolar open bites",
      publishDate: "September 15, 2020",
      // link: "https://medcraveonline.com/JDHODT/JDHODT-11-00533.pdf",
      link: "/assets/articles/JDHODT-11-00533.pdf",
    },
  ];
}
