import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { State } from "../models/state";
import { environment } from "../../environments/environment";
import { ModelService } from "./model.service";

@Injectable()
export class StatesService extends ModelService<State> {
  private statesUrl = environment.apiUrl + "states";

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * GET states from server
   * @returns {Observable<Country>}
   */
  // getUSStates():Observable<State[]> {
  //     return this.http.get<State[]>(this.statesUrl);
  // }

  /**
   * GET US states from server ONLY where there is a seminar
   * @returns {Observable<Object>}
   */
  getSeminarStates(): Promise<State[]> {
    return this.get(this.statesUrl + "/1").toPromise();
  }
}
