import { Seminar } from "./../models/seminar";
import { Country } from "./../models/country";
import { ModelService } from "./model.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
import { environment } from "../../environments/environment";

// const httpOptions = {
//     headers: new HttpHeaders({'Content-Type': 'application/json'})
// };

@Injectable()
export class SeminarService extends ModelService<Seminar> {
  private seminarsUrl = environment.apiUrl + "seminar";
  public seminarPanelOpen: boolean = false;
  seminars: Seminar[] = [];

  public transferSubject = new Subject<any>();

  transferRegistration(seminar: Seminar): void {
    this.transferSubject.next(seminar);
  }

  get transferRegistration$() {
    return this.transferSubject.asObservable();
  }

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * GET Seminars from server only where country matches
   * this may actually be changed to accept a country id and ! a string
   * @param country
   * @returns {Observable<Object>}
   */
  getSeminarsByCountry(country: any): Observable<Seminar[]> {
    const url = this.seminarsUrl + "/list/" + country;
    return this.get(url, country);
  }

  /**
   * GET Seminars from server only where country  and state matches parameters
   * @param country
   * @param state
   * @returns {Observable<Object>}
   */
  getSeminarsByCountryAndState(
    country: any,
    state: any
  ): Observable<Seminar[]> {
    const url = this.seminarsUrl + "/list/" + country + "/" + state;
    return this.http.get<Seminar[]>(url);
  }

  /**
   * GET seminars from server matching country or city
   * @param query
   * @returns {Observable<Array>|any}
   */
  searchSeminars(query: string): Observable<Seminar[]> {
    const url = this.seminarsUrl + "?term=" + query;
    if (!query.trim()) {
      return of([]);
    }
    return this.get(url);
  }
  /**
   *
   * This should work with an observable (it seems to function, but compiler complains)
   * todo: checkout the reason this blows something up if an observable is used here
   * @param id
   * @returns {any}
   */
  getSeminarById(id: number): Promise<Seminar> {
    const url = this.seminarsUrl + "/" + id;
    return this.get(url).toPromise();
  }

  sendContactForm(contact: any): Observable<any> {
    const url = this.seminarsUrl + "/contact";
    return this.post(url, contact);
  }

  /**
   * @param countryId
   * @param seminarId
   * Filters out ID of the current seminar and then opens the panel.
   */
  openTransferPanel(countryId, seminarId) {
    this.getSeminarsByCountry(countryId).subscribe((results) => {
      this.seminars = results.filter((i) => i.id !== seminarId);
      this.seminarPanelOpen = true;
    });
  }


  /**
   * @param countryId
   * @param seminarId
   */
  openTransferPanelAndCheckIfThisRegistrationBeginner(countryId, seminar: any) {
    this.getSeminarsByCountry(countryId).subscribe((results) => {
      // Only filter results if there were results:
      if (  !(!Array.isArray(results) || !results.length)  ) {
        this.seminars = results.filter((i) => i.id !== seminar.id);
      }
      const beginnerCourseTypes = [1, 2, 3, 7, "1", "2", "3", "7"]; // Not sure if here we have a string or a number.
      if (!beginnerCourseTypes.includes(seminar.type_course_id)) {
        // Do not show transfer courses, if this is not a beginner seminar. They are able to re-register instead for now.
        this.seminars = [];
      }
      this.seminarPanelOpen = true;
    });
  }

}
