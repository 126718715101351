import { StaffService } from "./staff.service";
import { Staff_member } from "./../models/staff_member";
import { Registration } from "./../models/registration";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class SidebarService {
  constructor(public staffService: StaffService) {}
  public registrationSubject = new Subject<any>();

  /*
  Staff side panel properties
  */
  public addStaffPanelOpened: boolean = false;
  public registration: Registration;
  public staff: Staff_member = new Staff_member();
  public editing: boolean = false;
  // Set different title "Add attendee" or "Edit attendee" for adding or editing a staff member:
  public modalTitle?: string = "Edit non-doctor staff member"; // "Edit attendee";

  /*
  Cancelation side panel properties
  */
  public cancelRegistrationOpened: boolean = false;

  /*
  Payment side panel properties
  */

  public paymentPanelOpened: boolean = false;
  public amountDue: number;

  //Staff panel methods
  openStaffPanel(registration: Registration, staff: Staff_member): void {
    this.addStaffPanelOpened = true;
    this.registration = registration;

    if (staff) {
      this.editing = true;
      this.staff = staff;
      this.staff.justAdded = false;
    } else {
      this.editing = false;
      this.staff = new Staff_member();
      this.staff.justAdded = true;
    }
  }

  closeStaffPanel(removeAttendee) {
    if (removeAttendee) {
      this.staffService.deleteStaff(this.staff);
    } else {
      this.staffService.setStaff(this.staff);
    }
    this.resetPanels();
  }

  triggerClose() {
    this.addStaffPanelOpened = false;
  }

  resetPanels(): void {
    this.staff = new Staff_member();
    this.registration = null;
  }

  isStaffValid(staff: Staff_member): boolean {
    if (!staff.name || !staff.email || !staff.profession || !staff.license) {
      return false;
    }
    return true;
  }

  //Registration panel methods
  cancelRegistration(): void {
    this.registrationSubject.next();
  }

  get cancelRegistration$() {
    return this.registrationSubject.asObservable();
  }

  //Payment panel methods
  openPaymentPanel(registration: Registration) {
    this.paymentPanelOpened = true;
    this.registration = registration;
  }

  public closePaymentPanel() {
    this.paymentPanelOpened = false;
  }
}
