import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EmbedVideoService } from "ngx-embed-video";

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
})
export class VideoPlayerComponent implements OnInit {
  @Input() bgColor: string;
  @Input() bgImg: string;
  @Input() videoUrl: string;
  @Input() isExternalLink: boolean;
  @Input() height: string;
  @Input() title: string = "Case Example";
  @Input() infoText: string = "";
  @Input() location: string = "";
  @Input() flagSrc: string;

  iframe_html: any;
  showVideo: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  openVideo() {
    this.showVideo = true;
  }
}
