import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-dentaltown",
  templateUrl: "./dentaltown.component.html",
  styleUrls: ["./dentaltown.component.scss"]
})
export class DentaltownComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  goToNews() {
    this.router.navigateByUrl("/news");
  }


}
