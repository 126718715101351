import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";

import { AlertService } from "../../service";
import { Subscription } from "rxjs";

import { Meta, Title } from "@angular/platform-browser";

import { Router } from "@angular/router";
import { KeyValuePairModel } from "../../components/select/models/keyvaluepair.model";
import { AlertType } from "app/models";

@Component({
  selector: "app-test",
  templateUrl: "./test.component.html",
  styleUrls: ["./test.component.scss"],
})
export class TestComponent implements OnInit, OnDestroy {
  private message: any;
  subscription: Subscription;
  asdfValidator = (value: string) => {
    const index = (value || "").indexOf("asdf");
    return index > -1;
  };

  selectorOptions: Array<KeyValuePairModel> = new Array<KeyValuePairModel>();
  selectedOption: KeyValuePairModel;

  constructor(
    config: NgbCarouselConfig,
    private alertService: AlertService,
    private meta: Meta,
    private title: Title,
    private router: Router
  ) {
    config.interval = 8000;

    this.subscription = this.alertService.getAlert().subscribe((message) => {
      this.message = message;
    });
    if (this.message) {
      this.alertService.success(this.message);
    }

    this.selectorOptions.push(new KeyValuePairModel("test", "test"));
    this.selectorOptions.push(new KeyValuePairModel("test2", "test2"));
    this.selectorOptions.push(new KeyValuePairModel("test3", "test3"));

    // this.selectedOption = this.selectorOptions[2];

    this.title.setTitle(
      // "Dental Braces: Straighten teeth in 120 days | Fastbraces"
      "Straighten teeth safely really fast | Fastbraces"
    );

    this.meta.addTags([
      {
        name: "description",
        content:
          // "Our technology helps dentists and orthodontists do comprehensive non-extraction orthodontics fast, with ease and quality. Straightens teeth in 120 days!",
          "Patented braces technology methods that help dentists straighten teeth really fast, safely and easily, even in about 100 days",
      },
      {
        name: "keywords",
        content:
          "braces, teeth braces, braces teeth, dental braces, braces on teeth, braces for teeth, ortho braces, dental brace, dental teeth braces, dental braces, braces ortho",
      },
    ]);

    //Let prerender know that this is not a valid path:
    if (this.router.url != "/") {
      this.meta.addTags([{ name: "prerender-status-code", content: "301" }]);
    }
  }

  testAlert() {
    this.alertService.info(
      "Thank you for signing up for the Fastbraces® Technologies Online Training Program. You have received a separate email with a link to create your password at our online portal, https://fastbracesonline.com. The first step is to create a password and login. Once you are logged in, please follow the instructions on the email or call our New Doctor Integration team specialists at ‭(972) 468-9285‬ so we can walk you through the website, answer your questions and help you get started with the online training program.",
      true
    );
    this.router.navigate([""]);
  }

  ngOnInit() {
    //scroll to top of window on init, we do this because viewport wants to stay
    //the same on route change
    window.scrollTo(0, 0);
  }

  //todo: Implement these destructors otherwise anything that's using subscriptions run the risk of causing memory leaks
  ngOnDestroy() {}
}
