import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-terms",
  templateUrl: "terms.component.html",
  styleUrls: ["terms.component.scss"],
})
export class TermsComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {
    this.title.setTitle("FASTBRACES® Terms of Use");

    this.meta.addTags([
      {
        name: "description",
        content: "Orthoworld L.L.C.(FASTBRACES®), Terms of Participation",
      },
      { name: "keywords", content: "" },
    ]);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
