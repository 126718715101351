import { Component, OnInit, Input, Output } from "@angular/core";
import { SeminarService } from "../../../../../service/seminar.service";
import { Seminar } from "../../../../../models/seminar";
import { Country } from "../../../../../models/country";
import { NgForm } from "@angular/forms";
import { CountryService } from "../../../../../service/country.service";
import { Registration } from "../../../../../models/registration";
import { RegistrationService } from "../../../../../service/registration.service";
import { AlertService } from "../../../../../service/alert.service";

@Component({
  selector: "app-transfer-registration",
  templateUrl: "./transfer-registration.component.html",
  styleUrls: ["./transfer-registration.component.css"],
})
export class TransferRegistrationComponent implements OnInit {
  @Input() registration: Registration;
  seminars: Seminar[];
  countries: Country[];
  selectedCountry: number;
  pendingAction: Boolean = false;

  constructor(
    private seminarService: SeminarService,
    private countryService: CountryService,
    private registrationService: RegistrationService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.selectedCountry = 226; /** Initialize the country dropdown */
    this.getCountries();
    this.getSeminars();
  }

  /**
   * Fetches all semianrs in the US
   */
  getSeminars(): void {
    this.seminarService
      .getSeminarsByCountry(226)
      .subscribe((seminars) => (this.seminars = seminars));
  }

  /**
   * Fetches a list of countries that have an active
   * seminar
   */
  getCountries(): void {
    this.countryService
      .getSeminarCountries()
      .subscribe((countries) => (this.countries = countries));
  }

  /**
   * Fetches a list of seminars for the selected country
   * @param selectedValue
   */
  updateSeminars(selectedValue: NgForm): void {
    //set property to user selected country value
    this.selectedCountry = selectedValue.value.selectedCountry;
    //replace contents of seminars array with new results
    this.seminarService
      .getSeminarsByCountry(this.selectedCountry)
      .subscribe((seminars) => (this.seminars = seminars));
  }

  /**
   * Transfer registration to a new seminar
   * @param newSeminarId
   */
  transferRegistration(newSeminarId: number): void {
    //used for visual feedback on PUT begin
    this.pendingAction = true;
    let transfer = this.setTransferData(newSeminarId);

    this.registrationService.transferRegistration(transfer).subscribe(
      (registration) => {
        this.registration = registration;
      },
      (error) => {
        this.alertService.error(error.error);
      },
      () => {
        this.pendingAction = false;
        window.scrollTo(0, 0);
        this.alertService.success(
          "Successfully Transferred you registration to the selected course"
        );
      }
    );
  }

  /**
   * Sets data needed to send to registration service to initiate
   * transfer
   * @param newSeminarId
   * @returns {{seminarId: number, registrationId: any}}
   */
  private setTransferData(newSeminarId: number): Transfer {
    return {
      seminarId: newSeminarId,
      registrationId: this.registration.id,
    };
  }
}

interface Transfer {
  seminarId: number;
  registrationId: number;
}
