import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-honors",
  templateUrl: "honors.component.html",
  styleUrls: ["honors.component.css"],
})
export class HonorsComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {
    this.title.setTitle("Success Stories | Fastbraces");

    this.meta.addTags([
      {
        name: "description",
        content:
          "Watch our success videos and hear from real patients how Fastbraces Technology has changed their lives with fast, safe, easy, and affordable treatments.",
      },
    ]);
  }

  ngOnInit() {}
}
