import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-biology",
  templateUrl: "./biology.component.html",
  styleUrls: ["./biology.component.css"],
})
export class BiologyComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {
    this.title.setTitle("Research & Technology | Fastbraces");

    this.meta.addTags([
      {
        name: "description",
        content:
          "Our technology has undergone university research and testing to ensure safety and optimal performance for patients. 30 years in the making.",
      },
    ]);
  }

  ngOnInit() {}
}
