import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ModelService } from "./model.service";

@Injectable()
export class AgreementPaymentService extends ModelService<Payment> {
  private agreementPaymentUrl = environment.apiUrl + "agreement";

  constructor(http: HttpClient) {
    super(http);
  }

  signWithPayment(payment: Payment): Observable<any> {
    //return this.post(this.agreementPaymentUrl+'/signAgreement', payment);
    //return this.http.post<any>(this.agreementPaymentUrl+'/signAgreement', payment);
    return this.postPaymentPayload(
      this.agreementPaymentUrl + "/signAgreement",
      payment
    );
  }

  reinstateWithPayment(payment: Payment): Observable<any> {
    return this.postPaymentPayload(
      this.agreementPaymentUrl + "/reinstateAgreement",
      payment
    );
  }
}

//As with the seminar payments - just to force type safety
interface Payment {
  name: string;
  token: string;
  email: string;
  ip: string;
  amount: number;
  registrationId: number;

  /** Sure, domains are not payment, but we send them at the same time */
  /**there's already a payment service why does this file exist?**/
  requestedDomains: string[];
  domainForwardUrl: string;
}
