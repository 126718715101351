import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ModelService } from "./model.service";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class LecturesService extends ModelService<any> {
  constructor(http: HttpClient) {
    super(http);
  }

  getLectures(): Observable<any> {
    return this.getPublications(environment.apiUrl + "lectures"); //'https://api.fastbraces.com/web_services/lectures');
  }
}
