import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  AfterViewInit,
  AfterContentInit,
  ContentChildren,
  ElementRef,
  ViewChild,
  Input,
  AfterContentChecked,
  AfterViewChecked,
} from "@angular/core";
import { FastResultsItemModel } from "./models/fast-results-item.model";

@Component({
  selector: "app-fast-results",
  templateUrl: "./fast-results.component.html",
  styleUrls: ["./fast-results.component.scss"],
})
export class FastResultsComponent implements AfterContentChecked, OnInit {
  currentIndex: number = 0;
  slides: FastResultsItemModel[] = [
    new FastResultsItemModel(
      "",
      0,
      "assets/images/pages/product/fast_results/day_0.png"
    ),
    new FastResultsItemModel(
      "The lingually displaced teeth move first more labially.",
      29,
      "assets/images/pages/product/fast_results/day_29.png"
    ),
    new FastResultsItemModel(
      "The labially displaced teeth begin alignment.",
      49,
      "assets/images/pages/product/fast_results/day_49.png"
    ),
    new FastResultsItemModel(
      "All the teeth become straighter.",
      69,
      "assets/images/pages/product/fast_results/day_69.png"
    ),
  ];

  mobile: boolean = false;

  constructor(private elementRef: ElementRef) {}

  getScrollableDiv(): HTMLElement {
    // tslint:disable-next-line: max-line-length
    return (this.elementRef.nativeElement as HTMLElement).parentElement
      .parentElement.parentElement.parentElement.parentElement.parentElement;
  }

  getActiveSlide(): FastResultsItemModel {
    let result = this.slides.filter((t) => t.visible).pop();

    if (result) {
      return result;
    } else {
      return this.slides[0];
    }
  }

  computeIsMobile(): void {
    if (window.innerWidth < 1024) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngOnInit(): void {
    window.addEventListener("resize", () => this.computeIsMobile());
    this.computeIsMobile();
  }

  ngAfterContentChecked(): void {
    this.currentIndex = 0;

    const elementPositionOnPage =
      this._getRect().top + this.getScrollableDiv().scrollTop;

    const slideDuration = Math.floor(
      window.innerHeight / (this.slides.length * 2.5)
    );

    let nextMinY = 0;
    let nextMargin = 0;
    const marginIncrement = 15;

    // tslint:disable-next-line: max-line-length
    let nextMaxY =
      elementPositionOnPage > window.innerHeight
        ? elementPositionOnPage -
          (window.innerHeight -
            this._getRect().height * (this.mobile ? 1 : 1.25))
        : window.innerHeight - slideDuration;

    for (let x = 0; x < this.slides.length; ++x) {
      const slide = this.slides[x];
      const computeNextMaxY = this.slides[x + 1] ? nextMaxY : 99999;

      slide.visibleAtMaxY = computeNextMaxY;
      slide.visibleAtMinY = nextMinY;
      slide.marginTop = -nextMargin;
      slide.marginLeft = nextMargin;
      // slide.visible = this._isBetween(
      //   this.getScrollableDiv().scrollTop,
      //   nextMinY,
      //   computeNextMaxY
      // );
      slide.visible = this.getScrollableDiv().scrollTop > nextMinY;

      nextMinY = computeNextMaxY + 1;
      nextMaxY = nextMinY + (slideDuration - 1);
      nextMargin = nextMargin + marginIncrement;
    }
  }

  private _isBetween(number: number, a: number, b: number): boolean {
    const min = Math.min(a, b);
    const max = Math.max(a, b);

    return number >= min && number <= max;
  }

  private _getRect(): DOMRect {
    const element: HTMLElement = this.elementRef.nativeElement.children[0];

    return element.getBoundingClientRect();
  }
}
