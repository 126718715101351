import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Registration } from "../../models/registration";
import { AlertService } from "../../service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RegistrationService } from "../../service/registration.service";

@Component({
  selector: "app-agreement",
  templateUrl: "./agreement.component.html",
  styleUrls: ["./agreement.component.scss"],
})
export class AgreementComponent implements OnInit {
  registration: Registration;
  hideRegistration: boolean = false;
  editToken: string;
  showPayment: boolean = true; //false;
  verifyInformation: boolean = true;
  showAgreementPreview: boolean = false;
  dataIsFetching: boolean = true;

  constructor(
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.editToken = this.route.snapshot.paramMap.get("token");
    this.getRegistration();
  }

  ngAfterViewInit() {
    //console.log("========DEBUGGING========");
    //console.log(this);
  }

  /**
   * Get registration by edit token
   */
  getRegistration(): void {
    this.registrationService
      .getRegistrationByToken(this.editToken)
      .then((registration) => (this.registration = registration))
      .then(() => this.registrationCallback());
  }

  /**
   * Provides callback call to set the value of 'this.hideRegistration' once initial promise is ready
   */
  registrationCallback(): void {
    /*
        if((this.registration.deleted == 1)||(!this.registration.attended)||(!(['Yes','1'].includes(this.registration.attended)))){
            this.hideRegistration=true;
        }
        */

    var forbiddenStatusArray = ["1", "3", "4", "10", "11", 1, 3, 4, 10, 11]; //the stupidest thing so far
    if (
      this.registration.deleted == 1 ||
      forbiddenStatusArray.indexOf(this.registration.registered_status_id) !==
        -1
    ) {
      this.hideRegistration = true;
    }

    this.dataIsFetching = false;
  }

  /**
   * Open NgbModal modal
   */
  openModal(content): void {
    this.modalService.open(content);
  }

  /**
   * Show window to enter CC information
   */
  agreementPayment(): void {
    this.showPayment = true; //!this.showPayment;
    this.verifyInformation = false;
    this.showAgreementPreview = false;
    window.scrollTo(0, 0);
  }

  /**
   * Show a preview of the licensing agreement
   */
  previewAgreement(): void {
    this.showAgreementPreview = true;
    this.verifyInformation = false;
    this.showPayment = false;
    window.scrollTo(0, 0);
  }

  /**
   * Show a preview of the licensing agreement
   */
  verifyInformationView(): void {
    this.verifyInformation = true;
    this.showAgreementPreview = false;
    this.showPayment = false;
    window.scrollTo(0, 0);
  }

  /**
   * Disable the agreement page's navigation buttons while the request is processing.
   * @param $message
   */
  waitWhileSendingSignarture($message) {
    var disable_button_1 = document.getElementById("show_agreement_button");
    disable_button_1.setAttribute("disabled", "true");

    var disable_button_2 = document.getElementById("show_information_button");
    disable_button_2.setAttribute("disabled", "true");
  }
}
