import {
  Component,
  Input,
  forwardRef,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() type: string = "text";
  @Input() id: string;
  @Input() name: string;
  @Input() pattern: string;
  @Input() label: string = "Default Label";
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() appendOptional: boolean = true;
  @Input() value: string = "";
  @Output() valueChange = new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() validate = (value: string) => true;
  @Input() errorMessage: string;
  valid: boolean = true;
  pristine: boolean = true;
  hasFocus: boolean = false;

  // Function to call when the rating changes.
  onChange = (string: string) => {};
  // Function to call when the input is touched (when a star is clicked).
  onTouched = () => {};

  constructor() {}

  ngOnInit() {
    if (!this.required && this.appendOptional) {
      this.label += " (Optional)";
    }
    if (!this.placeholder) {
      this.placeholder = this.label;
    }
  }

  writeValue(value: string): void {
    this.onChange(value);
    this.valueChange.emit(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputFocus(): void {
    this.pristine = false;
    this.placeholder = "";
    this.hasFocus = true;
  }

  onInputChange(): void {
    this.valid = this.isValid();
    if (!this.disabled) {
      this.writeValue(this.value);
    }
  }

  onInputBlur(): void {
    this.hasFocus = false;
  }

  isValid(): boolean {
    let isValid = this.validate(this.value);
    if (this.required && !this.value) {
      isValid = false;
    }
    return isValid;
  }
}
