import { SidebarService } from "./../../../../service/sidebar-controll.service";
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Registration } from "../../../../models/registration";
import { NgForm } from "@angular/forms";
import { AlertService } from "../../../../service";
import { PaymentService } from "../../../../service/payment.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RegistrationService } from "../../../../service/registration.service";
import { Staff_member } from "../../../../models/staff_member";
import { AnonymousSubject } from "rxjs/internal/Subject";

@Component({
  selector: "app-seminar-payment",
  templateUrl: "./seminar-payment.component.html",
  styleUrls: ["./seminar-payment.component.scss"],
})
export class SeminarPaymentComponent implements OnInit {
  @ViewChild("cardInfo", { static: true }) cardInfo: ElementRef;
  @ViewChild("expDate", { static: true }) expdate: ElementRef;
  @ViewChild("cvc", { static: true }) cvc: ElementRef;

  @Input() registration: Registration;
  card: any;
  expirationDate: any;
  cardCvc: any;
  error: string;
  cardHandler = this.onChange.bind(this);
  totalAmountDue: number;
  sending: boolean = false; /* Flag that data sending is in progress */
  staffMembers: Staff_member[];
  displayCheckout: boolean = true;
  disabled: boolean = false;

  constructor(
    private changeDetect: ChangeDetectorRef,
    private alertService: AlertService,
    private paymentService: PaymentService,
    private router: Router,
    private registrationService: RegistrationService,
    private elementRef: ElementRef,
    public sidebarService: SidebarService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}

  public style = {
    base: {
      iconColor: "#666EE8",
      backgroundColor: "#F5F7FF",
      color: "#472F91",
      fontSize: "16px",

      "::placeholder": {
        color: "#472F91",
      },
    },
  };

  ngAfterViewInit() {
    this.card = elements.create("cardNumber", {
      style: this.style,
      placeholder: "Card number",
    });
    this.expirationDate = elements.create("cardExpiry", {
      style: this.style,
      placeholder: "MM/YY",
    });
    this.cardCvc = elements.create("cardCvc", {
      style: this.style,
      placeholder: "CVC",
    });
    this.card.mount(this.cardInfo.nativeElement);
    this.expirationDate.mount(this.expdate.nativeElement);
    this.cardCvc.mount(this.cvc.nativeElement);
    this.card.addEventListener("change", this.cardHandler);
    this.calculateTotalAmountDue();

    //let inputs = this.elementRef.nativeElement.querySelector();
  }
  /**
   * We need to call the destroy API here in order to avoid attempting to add a duplicate card element if payment is
   * declined
   */
  ngOnDestroy() {
    this.card.removeEventListener("change", this.cardHandler);
    this.card.destroy();
    this.expirationDate.destroy();
    this.cardCvc.destroy();
  }

  /**
   * Calculates and sets the total amount due for the payment
   */
  calculateTotalAmountDue(): void {
    this.registrationService
      .getStaffMembers(this.registration.id)
      .subscribe((staffMembers) => {
        this.staffMembers = staffMembers;
        let staffAttending: number = this.registration.staff_attending - 1;

        //This will not show 'pay' button if staff members are not entered...
        if (staffMembers.length == 0 && staffAttending - 1 > 0) {
          this.displayCheckout = false;
        }

        let ownerFee = 0;
        let eachStaffFee = 295;

        // For business seminar, owner fees are different:
        if (
          this.registration &&
          this.registration.type_course_id &&
          this.registration.type_course_id == "9"
        ) {
          // If this is an outsider:
          ownerFee = 4950;
          // Business staff here is also 495:
          eachStaffFee = 495;

          if (this.registration.fbstatus) {
            if (this.registration.fbstatus == "none") {
              ownerFee = 4950;
              eachStaffFee = 4950;
            } else if (this.registration.fbstatus == "simple") {
              //ownerFee = 1995;
              ownerFee = 495;
            } else if (this.registration.fbstatus == "master") {
              //ownerFee = 995;
              ownerFee = 495;
            } else if (this.registration.fbstatus == "senior_master") {
              //ownerFee = 495;
              ownerFee = 495;
            } else if (this.registration.fbstatus == "staff") {
              ownerFee = 0; //staff fee will be added at further calculations
            }
          }
        }

        //since this value will include the doctor as well, so we need to subtract one from this value
        let staffFee = eachStaffFee * staffAttending;
        this.totalAmountDue = ownerFee + staffFee;

        if (
          this.registration.profession == "Dental Hygienist" ||
          this.registration.profession == "Staff Member"
        ) {
          this.totalAmountDue = this.totalAmountDue + eachStaffFee;
        }

        this.sidebarService.amountDue = this.totalAmountDue;

        //console.log(this.staffMembers);
      });
  }
  /**
   * Displays any error that stripe elements may trigger, event listener attached
   * to check for errors
   * @param error
   */
  onChange(error): void {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.changeDetect.detectChanges();
  }

  /**
   *
   * Requests token from stripe and calls to server
   * to charge doctor
   * @param form
   */
  async checkOut(form: NgForm) {
    const { token, error } = await stripe.createToken(this.card);

    if (error) {
      this.alertService.error(error.message);
    } else {
      let payment = this.setPaymentData(token);
      this.paymentService.sendPayment(payment).subscribe(
        (response) => {
          this.registration.fee_paid = 1;
          this.alertService.success(
            "You have registered for FASTBRACES® Provider Training Course which is currently " +
              "pending approval. Please allow up to 72 hours for us to call you and confirm your registration. " +
              "Once approved you will receive an automatic confirmation email.",
            true
          );
          this.sidebarService.closePaymentPanel();
          //this.router.navigate([this.route.url])
          this.disabled = false;
        },
        (error) => {
          this.alertService.error(error.error[0]);
          this.sending = false;
          this.sidebarService.closePaymentPanel();
          this.disabled = false;
        }
      );
    }
  }

  /**
   * Returns the object we will be posting to the backend to create the charge
   * @param token
   * @returns {{name: string, token: any, email: string, ip: (any|string), amount: number}}
   */
  private setPaymentData(token): Payment {
    return {
      name: this.registration.dentist_name,
      token: token.id,
      email: this.registration.dentist_email,
      ip: token.client_ip,
      amount: this.totalAmountDue,
      registrationId: this.registration.id,
    };
  }
}

//again just to force type safety
interface Payment {
  name: string;
  token: string;
  email: string;
  ip: string;
  amount: number;
  registrationId: number;
}
