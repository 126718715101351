import { Subject } from "rxjs";
import { RegistrationService } from "app/service/registration.service";
import { Staff_member, Staff } from "./../models/staff_member";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ModelService } from "./model.service";

@Injectable()
export class StaffService extends ModelService<any> {
  constructor(
    http: HttpClient,
    private registrationService: RegistrationService
  ) {
    super(http);
  }

  public staffMembers: Staff_member[] = [];
  public staff: Staff_member = new Staff_member();

  public staffSaved = new Subject<any>();
  public deleteSaved = new Subject<any>();

  public setStaffMembers(staff: Staff_member[]): void {
    this.staffMembers = staff;
  }

  public setStaff(staff: Staff_member): void {
    this.staffSaved.next(staff);
  }

  get staff$() {
    return this.staffSaved.asObservable();
  }

  get delete$() {
    return this.deleteSaved.asObservable();
  }

  public getStaff(): Staff_member {
    return this.staff;
  }

  public deleteStaff(staff: Staff_member): void {
    if (staff.id) {
      this.deleteSaved.next(staff);
    }
  }
}
