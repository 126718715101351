import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";

import { AlertService } from "../../service";
import { Subscription } from "rxjs";

import { Meta, Title } from "@angular/platform-browser";

import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit, OnDestroy {
  private message: any;
  subscription: Subscription;

  constructor(
    config: NgbCarouselConfig,
    private alertService: AlertService,
    private meta: Meta,
    private title: Title,
    public router: Router
  ) {
    config.interval = 8000;

    this.subscription = this.alertService.getAlert().subscribe((message) => {
      this.message = message;
    });
    if (this.message) {
      this.alertService.success(this.message);
    }

    this.title.setTitle(
      // "Dental Braces: Straighten teeth in 120 days | Fastbraces"
      "Straighten teeth safely really fast | Fastbraces"
    );

    this.meta.addTags([
      {
        name: "description",
        content:
          // "Our technology helps dentists and orthodontists do comprehensive non-extraction orthodontics fast, with ease and quality. Straightens teeth in 120 days!",
          "Patented braces technology methods that help dentists straighten teeth really fast, safely and easily, even in about 100 days",
          // todo: "The technology developed to move teeth fast from the simplest to the toughest of cases",
      },
      {
        name: "keywords",
        content:
          "braces, teeth braces, braces teeth, dental braces, braces on teeth, braces for teeth, ortho braces, dental brace, dental teeth braces, dental braces, braces ortho",
      },
    ]);

    //Let prerender know that this is not a valid path:
    if (this.router.url != "/") {
      this.meta.addTags([{ name: "prerender-status-code", content: "301" }]);
    }
  }

  ngOnInit() {
    //scroll to top of window on init, we do this because viewport wants to stay
    //the same on route change
    window.scrollTo(0, 0);
  }

  goToBeforePage() {
    this.router.navigateByUrl("/before_and_after");
  }

  goToResearchPage() {
    this.router.navigateByUrl("/recent-publications");
  }

  goToTrainingPage() {
    this.router.navigateByUrl("/seminars");
  }

  goToPubPage() {
    this.router.navigateByUrl("/publications");
  }


  openPublicationLink(url): void {
    window.open(url, "_blank");
  }


  //todo: Implement these destructors otherwise anything that's using subscriptions run the risk of causing memory leaks
  ngOnDestroy() {}
}
