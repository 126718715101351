import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-works",
  templateUrl: "./works.component.html",
  styleUrls: ["./works.component.css"],
})
export class WorksComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {
    this.title.setTitle("How Our Dental Braces Work on Teeth | Fastbraces");

    this.meta.addTags([
      {
        name: "description",
        content:
          "See how our unique braces work. Our technology will straighten your patient's teeth in 120 days or less. Fast and comfortable, with effortless fit.",
      },
      {
        name: "keywords",
        content:
          "how do braces work, how braces work, how braces move teeth, how do braces work on teeth, braces how they work, how do dental braces work, how braces works, how braces work on teeth",
      },
    ]);
  }

  ngOnInit() {}
}
