import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: "app-doctor-support",
  templateUrl: "./doctor-support.component.html",
  styleUrls: ["./doctor-support.component.scss"]
})
export class DoctorSupportComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

}
