// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //apiUrl: "https://api.fastbraces2.com/web_services/", //test on prod
  apiUrl: "http://api.fastbraces.local/web_services/",

  // apiUrl: "https://api.devfastbraces.com/web_services/",
  //apiUrl: "https://api.fastbraces2.com/web_services/",
  //apiUrl: "https://api.fastbraces.com/web_services/",
};
