import { map } from "rxjs/operators";
import { Subscription } from "rxjs";
import { StaffService } from "./../../../service/staff.service";
import { Staff_member, Staff } from "./../../../models/staff_member";
import { SidebarService } from "./../../../service/sidebar-controll.service";
import { Component, OnInit } from "@angular/core";
import { SeminarService } from "../../../service/seminar.service";
import { Seminar } from "../../../models/seminar";
import { ActivatedRoute } from "@angular/router";
import { Registration } from "../../../models/registration";
import { Country } from "../../../models/country";
import { AlertService } from "../../../service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RegistrationService } from "../../../service/registration.service";
import { CountryService } from "../../../service/country.service";
import { isNull } from "util";

@Component({
  selector: "app-edit-registration",
  templateUrl: "./edit-registration.component.html",
  styleUrls: ["./edit-registration.component.scss"],
})
export class EditRegistrationComponent implements OnInit {
  registration: Registration = new Registration();
  countries: Country[];
  seminarId: number;
  editToken: string;
  seminar: Seminar;
  staffMembers: Staff_member[];
  originalRegistree: Staff_member;

  showTransferRegistration: boolean = false;
  showStaffMembers: boolean = false;
  showUploadImage: boolean = false;
  showSeminarPayment: boolean = false;
  sending: boolean = false;
  _opened: boolean = false;
  showCancelContainer: boolean = false;
  countryArray: string[] = [];
  countrySelected: string;

  //StaffErrorModels
  public staffErrorSuccessMessage: string;
  public staffSuccessMessage: string;
  public errorModifyingStaff: boolean = undefined;

  //Registration Error
  public errorCreatingStaff: boolean = false;
  public staffCreated: boolean = false;
  public errorMessage: string;
  public message: string;
  public title: string;
  public errorTitle: string;

  /***
   *
   * MAKE SERVICES FOR THESE
   * @type {{profession: string}[]}
   */
  professions: string[] = [
    "General Dentist",
    "Orthodontist",
    "Dental Hygienist",
    "Staff Member",
    "Pedodontist",
    "Prosthodontist",
    "Periodontist",
    "Oral Surgeon",
    "Endodontist",
  ];

  /*
  referrals: string[] = [
    "Digital Ad",
    "Doctor Referral",
    "Email",
    "Facebook",
    // "Google",
    "Instagram",
    "Internet Search",
    "LinkedIn",
    "Magazine",
    // "Chrisad",
    // "Phone Call",
    "Postcard",
    "Press Release",
    "Twitter",
    "YouTube",
  ];
  */
   referrals: string[] = [
    "Social Media",
    "Dentaltown Magazine",
    "The Profitable Dentist",
    "Doctor Referral",
    "Email",
    "Postcard",
    "Letter",
    // "Text Message",
    // "FedEx Letter",
    "Internet Search",
    // "JCO Magazine",
    // "Letter",
    // "Orthotown Magazine",
    // "TikTok",
    "Chrisad",
  ];

  owners: Owner[] = [{ status: "Yes" }, { status: "No" }];

  constructor(
    public seminarService: SeminarService,
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
    private countryService: CountryService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private sidebar: SidebarService,
    private staff: StaffService
  ) {}

  ngOnInit() {
    this.staff.staff$.forEach((staff: Staff_member) => {
      this.addStaffMember(staff);
    });

    this.staff.delete$.forEach((staff: Staff_member) => {
      this.deleteStaff(staff, this.registration.id);
    });

    this.sidebar.cancelRegistration$.forEach(() => {
      this.cancelRegistration();
    });

    this.seminarService.transferRegistration$.forEach((seminar) => {
      this.transferRegistration(seminar);
    });

    this.countryService.getCountries().subscribe((countries) => {
      this.countryArray = countries.map((country: Country) => {
        return country.name;
      });

      this.countries = countries;
      this.editToken = this.route.snapshot.paramMap.get("token");
      this.getRegistration();
    });
  }

  transferRegistration(seminar: Seminar): void {
    const transfer = {
      seminarId: seminar.id,
      registrationId: this.registration.id,
    };
    this.staffErrorSuccessMessage =
      "Something went wrong transfering the registration please try again at a later time.";
    this.staffSuccessMessage = "Registration transfered.";

    this.registrationService.transferRegistration(transfer).subscribe(
      (registration) => {
        this.registration = registration;
        this.staffCreated = true;
        this.errorModifyingStaff = false;
        this.seminarService.seminarPanelOpen = false;
        this.seminar = seminar;
      },
      (error) => {
        this.alertService.error(error.error);
        this.staffCreated = false;
        this.errorModifyingStaff = true;
        this.seminarService.seminarPanelOpen = false;
      }
    );
  }

  openPaymentPanel() {
    this.sidebar.openPaymentPanel(this.registration);
  }
  reinstateRegistration() {
    this.registration.registered_status_id = 1;
    this.updateRegistration();
    this.showCancelContainer = false;
    this.staffErrorSuccessMessage =
      "Something went wrong reinstating the registration please try again at a later time.";
    this.staffSuccessMessage = "Registration reinstated.";
  }
  cancelRegistration() {
    this.registration.registered_status_id = 4;
    this.updateRegistration();
    this.showCancelContainer = true;
    this.sidebar.cancelRegistrationOpened = false;
    this.staffErrorSuccessMessage =
      "Something went wrong cancelling the registration please try again at a later time.";
    this.staffSuccessMessage = "Registration cancelled.";
  }

  deleteStaff(staff: Staff_member, registrationId: number) {
    this.staffErrorSuccessMessage =
      "Something went wrong removing the attendee please try again at a later time.";
    this.staffSuccessMessage = "Attendee removed.";

    this.registrationService
      .deleteStaffMember(staff.id, registrationId)
      .subscribe(
        (results) => {
          this.staff.staffMembers = this.staff.staffMembers.filter(
            (i) => i.id !== staff.id
          );

          this.sidebar.addStaffPanelOpened = false;
          this.errorModifyingStaff = false;
          this.registration.staff_attending =
            Number(this.registration.staff_attending) - 1;

          return {
            Response: 200,
            Message: "Attendee updated.",
          };
        },
        (err) => {
          this.sidebar.addStaffPanelOpened = false;
          this.errorModifyingStaff = true;
          console.log(err);
          return {
            Response: 400,
            Message: "Error updating Attendee.",
          };
        }
      );
  }

  addStaffMember(staff: Staff_member) {
    if (staff.id) {
      this.updateStaff(staff, this.seminar.id, this.registration.id);
    } else {
      this.addStaff(staff, this.seminar.id, this.registration.id);
    }
  }

  updateStaff(st: Staff_member, seminarId: number, registrationId: number) {
    const staff = {
      name: st.name,
      profession: st.profession,
      license: st.license,
      email: st.email,
      id: st.id,
      registration_Id: registrationId,
      seminar_id: seminarId,
    } as Staff_member;
    this.staffErrorSuccessMessage =
      "Something went wrong updating the attendee please try again at a later time.";
    this.staffSuccessMessage = "Attendee updated.";
    this.registrationService.putSeminarStaffMember(staff).subscribe(
      (results) => {
        this.sidebar.addStaffPanelOpened = false;
        this.errorModifyingStaff = false;

        return {
          Response: 200,
          Message: "Attendee updated.",
        };
      },
      (err) => {
        this.sidebar.addStaffPanelOpened = false;
        this.errorModifyingStaff = true;
        console.log(err);
        return {
          Response: 400,
          Message: "Error updating Attendee.",
        };
      }
    );
  }

  addStaff(st: Staff_member, seminarId: number, registrationId: number) {
    const staff = {
      name: st.name,
      profession: st.profession,
      license: st.license,
      email: st.email,
      registrationId: registrationId,
      seminarId: seminarId,
    } as Staff;

    this.staffSuccessMessage = "Attendee Created.";
    this.staffErrorSuccessMessage =
      "Something went wrong creating the attendee please try again at a later time.";

    this.registrationService.addStaffMember(staff).subscribe(
      (results) => {
        this.sidebar.addStaffPanelOpened = false;
        this.staff.staffMembers.push(results);
        this.errorModifyingStaff = false;
        this.registration.staff_attending =
          Number(this.registration.staff_attending) + 1;
        return {
          Response: 200,
          Message: "Attendee created.",
        };
      },
      (err) => {
        this.sidebar.addStaffPanelOpened = false;
        this.errorModifyingStaff = true;
        return {
          Response: 400,
          Message: "Error creating Attendee.",
        };
      }
    );
  }

  editStaff(staff): void {
    this.sidebar.openStaffPanel(this.registration, staff);
  }

  getRegistrationStaffMembers(): void {
    this.registrationService
      .getStaffMembers(this.registration.id)
      .subscribe((staffMembers: Staff_member[]) => {
        this.staff.staffMembers = staffMembers;
        this.staff.staffMembers.push(this.originalRegistree);
      });
  }

  enablePay() {
    //If this an unpaid business seminar with only 1 attendee preset:
    if (
      this.seminar &&
      this.seminar.type_course_id &&
      this.seminar.type_course_id == 9 &&
      this.registration.fee_paid != 1 &&
      this.registration.staff_attending == 1
    ) {
      return true;
    }

    // Update this logic, what if they originally registered > 2 members, but did not add them:
    //todo: check to make sure number of staff members matches registration staff member number.

    if (this.staff.staffMembers.length === 1) {
      let staff = this.staff.staffMembers[0];

      if (
        staff &&
        (staff.profession == "Dental Hygienist" ||
          staff.profession == "Staff Member") &&
        this.registration.fee_paid != 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  setCountry(): void {
    if (this.countrySelected) {
      const country = this.countries.find(
        (i) => i.name === this.countrySelected
      );
      this.registration.country = country.country_id;
      this.registration.country_id = country.country_id.toString();
    }
  }

  /**
   * Get registration by edit token
   */
  getRegistration(): void {
    this.registrationService
      .getRegistrationByToken(this.editToken)
      .then((registration) => {
        this.registration = registration;
        const staffMember = new Staff_member();
        staffMember.name = `${registration.first_name} ${registration.last_name}`;
        staffMember.email = registration.dentist_email;
        staffMember.license = registration.license_number;
        staffMember.profession = registration.profession;
        staffMember.isOriginalRegister = true;
        if (
          staffMember.profession !== "Dental Hygienist" &&
          staffMember.profession !== "Staff Member" &&
          registration.fee_paid != 1
        ) {
          staffMember.paid = 0;
        }

        this.originalRegistree = staffMember;
      })
      .then(() => this.registrationCallback())
      .then(() => this.getRegistrationStaffMembers());
  }

  /**
   * Provides callback call to get seminar once
   * initial promise is ready
   */
  registrationCallback(): void {
    if (this.registration.registered_status_id == 4) {
      // this.alertService.error(
      //   "This registration has been canceled. Please reinstate the registration if you plan to attend this seminar."
      // );
      this.showCancelContainer = true;
    }
    if (this.registration.registered_status_id == 3) {
      // this.alertService.error(
      //   "This registration has NOT been confirmed. Please CANCEL AND THEN REINSTATE the registration if you plan to attend this seminar."
      // );
    }
    if (this.registration.deleted == 1) {
      // this.alertService.warn(
      //   "This registration has been DELETED. If you feel this is a mistake, please feel free to contact us (or to simply re-register). Thank you!"
      // );
    }
    this.seminarId = this.registration.courses_id;
    if (this.countries) {
      this.countrySelected =
        this.countries.find(
          (i) => i.country_id.toString() === this.registration.country_id
        )?.name ?? "";
    }
    this.getSeminar();
  }
  /**
   * Get seminar by seminar id
   */
  getSeminar(): void {
    this.seminarService.getSeminarById(this.seminarId).then((seminar) => {
      this.seminar = seminar;
    });
  }

  /**
   * Open NgbModal modal
   */
  openModal(content): void {
    this.modalService.open(content);
  }

  /**
   *
   * Calls to update current registration with newly entered
   * values.
   */
  updateRegistration(): void {
    this.sending = false;

    this.setCountry();

    /** Field that should not be sent (can only delete 'fee_paid' when fee functionality is disabled, otherwise payment button will return). If staff fee comes back - fix! */
    delete this.registration["fee_paid"];

    // New values to NOT be sent:
    delete this.registration["type_course_id"]; //Not an actual field
    delete this.registration["fbstatus"]; //Actual field
    delete this.registration["price"]; //Actual field
    delete this.registration["paid_amount"]; //Actual field

    this.registrationService.putRegistration(this.registration).subscribe(
      (registration) => {
        this.registration = registration;
        this.message = "Update was successful.";
        this.title = "Success";
        this.staffCreated = true;
        this.errorModifyingStaff = false;
        this.sending = false;
      },
      (error) => {
        this.errorMessage = "Update was not successful. Please try again. ";
        this.errorTitle = "Error";
        this.errorCreatingStaff = true;
        this.errorModifyingStaff = true;
        this.sending = false;
      }
    );
  }

  /**
   * Does the registration have a non-empty/non-zero records user id?
   * @returns {boolean}
   */
  hasRecordsUserId(): boolean {
    if (
      this.registration.records_user_id &&
      this.registration.records_user_id !== "0" &&
      !isNull(this.registration.records_user_id)
    ) {
      return true;
    }
    return false;
  }

  public scrollToTop() {
    window.scrollTo(0, 0);
  }
}

//for type safety
interface Profession {
  profession: string;
}
interface Referral {
  source: string;
}
interface Owner {
  status: string;
}
