import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-history",
  templateUrl: "history.component.html",
  styleUrls: ["history.component.css"],
})
export class HistoryComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {
    this.title.setTitle("Our 25 Year History | Fastbraces");

    this.meta.addTags([
      {
        name: "description",
        content:
          "View information about the 25 year history of Fastbraces- a vision towards smiles for people all over the world!",
      },
    ]);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
