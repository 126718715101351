import {
  AfterContentChecked,
  Component,
  ElementRef,
  OnInit,
} from "@angular/core";
import { SeminarService } from "../../service/seminar.service";
import { AlertService } from "../../service/alert.service";

@Component({
  selector: "app-contact",
  templateUrl: "contact.component.html",
  styleUrls: ["contact.component.scss"],
})
export class ContactComponent implements OnInit {
  sending: boolean = false; /* Flag that data sending is in progress */
  contact: contactForm = <contactForm>{}; //this cake is a lie, please take note for whoever bes here, we are braking type safety by lying here

  constructor(
    private element: ElementRef,
    private seminarService: SeminarService,
    private alertService: AlertService
  ) {}

  getScrollableElement(): HTMLElement {
    const parent = (this.element.nativeElement as HTMLElement).parentElement
      .parentElement.parentElement;
    console.log(parent);
    return parent;
  }

  ngOnInit() {
    this.getScrollableElement().scrollTo(0, 0);
  }

  /**
   * POST the question to backend API
   * */
  submitContactForm(): void {
    this.sending = true;
    this.seminarService.sendContactForm(this.contact).subscribe(
      () => {
        this.alertService.success(
          "Thank you. Your question has been submitted."
        );
        this.sending = false;
        this.getScrollableElement().scrollTo(0, 0);
      },
      (error) => {
        var errorMessage = "An error occurred, please try again. ";
        if (typeof error.error !== "undefined") {
          errorMessage += error.error;
        }
        this.alertService.error(errorMessage);
        this.sending = false;
        this.getScrollableElement().scrollTo(0, 0);
      }
    );
  }
}

interface contactForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  question: string;
  country: string;
}
