import {
  Component,
  OnInit,
  Input,
  ElementRef,
} from "@angular/core";

@Component({

  selector: "app-empty-slide-compare",
  templateUrl: "./empty-slide.compare.component.html",
  styleUrls: ["./empty-slide.compare.component.scss"],
})
export class EmptySlideCompareComponent implements OnInit {
  @Input() name: string;
  @Input() title: string;
  @Input() replaces_days: string;
  @Input() days: number;
  @Input() beforeSrc: string;
  @Input() afterSrc: string;
  @Input() imageWidth: number = 650;
  @Input() imageHeight: number = 304;
  @Input() flagSrc: string;

  private _mouseDown: boolean = false;
  private _slideParent: HTMLDivElement;
  private _slider: HTMLDivElement;
  private _images: HTMLDivElement;
  private _before: HTMLDivElement;
  private _after: HTMLDivElement;
  private _maxWidth: number;
  private _offsetX: number;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    window.addEventListener("resize", () => this.handleResize());
    this._slideParent = this.elementRef.nativeElement
      .childNodes[0] as HTMLDivElement;
    this._slider = this._slideParent.children.namedItem(
      "slider"
    ) as HTMLDivElement;
    this._images = this._slideParent.children.namedItem(
      "images"
    ) as HTMLDivElement;
    this._before = this._images.children.namedItem("before") as HTMLDivElement;
    this._after = this._images.children.namedItem("after") as HTMLDivElement;

    this._maxWidth = this._slideParent.clientWidth;

    this._slideParent.onmousedown = (ev) =>
      this.handleDown(ev, ev.offsetX, false);
    this._slideParent.onmouseup = (ev) => this.handleUp();
    this._slideParent.onmousemove = (ev) => this.handleMove(ev.offsetX, false);

    this._slideParent.ontouchstart = (ev) =>
      this.handleDown(ev, ev.touches[0].clientX, true);
    this._slideParent.ontouchend = (ev) => this.handleUp();
    this._slideParent.ontouchmove = (ev) =>
      this.handleMove(ev.touches[0].clientX, true);

    this._before.style.backgroundImage = `url('${this.beforeSrc}')`;
    this._after.style.backgroundImage = `url('${this.afterSrc}')`;

    this.handleResize();
  }

  handleResize = () => {
    const childElement = (this.elementRef.nativeElement as HTMLElement)
      .children[0].children["images"];

    const containerWidth = childElement.getBoundingClientRect().width;
    const containerHeight = childElement.getBoundingClientRect().height;

    let newImageHeight = 0;
    let newImageWidth = 0;

    newImageHeight = this.imageHeight * (containerWidth / this.imageWidth);
    newImageWidth = containerWidth;

    const xAdjustment = (containerWidth - newImageWidth) / 2;
    const yAdjustment = (containerHeight - newImageHeight) / 2;

    this._before = this._images.children.namedItem("before") as HTMLDivElement;
    this._after = this._images.children.namedItem("after") as HTMLDivElement;
    this._slideParent = this.elementRef.nativeElement
      .childNodes[0] as HTMLDivElement;

    this._maxWidth = this._slideParent.clientWidth;
    this._offsetX = this._slideParent.getBoundingClientRect().x;

    this._before.style.backgroundPositionX = `${xAdjustment}px`;
    this._before.style.backgroundPositionY = `${yAdjustment}px`;

    this._after.style.backgroundPositionX = `${xAdjustment}px`;
    this._after.style.backgroundPositionY = `${yAdjustment}px`;

    this._before.style.backgroundSize = `${newImageWidth}px ${newImageHeight}px`;
    this._after.style.backgroundSize = `${newImageWidth}px ${newImageHeight}px`;
  };

  handleDown = (ev: Event, xPosition: number, useOffset: boolean) => {
    ev.preventDefault();

    if (useOffset) {
      xPosition = xPosition - this._offsetX;
    }

    if (xPosition > this._maxWidth) {
      xPosition = this._maxWidth;
    }

    this._mouseDown = true;
    this._slider.style.width = `${xPosition}px`;
    this._before.style.width = `${xPosition - 20}px`;
  };

  handleUp = () => {
    this._mouseDown = false;
  };

  handleMove = (xPosition: number, useOffset) => {
    if (useOffset) {
      xPosition = xPosition - this._offsetX;
    }

    if (xPosition > this._maxWidth) {
      xPosition = this._maxWidth;
    }

    if (this._mouseDown) {
      this._slider.style.width = `${xPosition}px`;
      this._before.style.width = `${xPosition - 20}px`;
    }
  };
}
