import { RegistrationService } from "./service/registration.service";
import { SeminarService } from "app/service/seminar.service";
import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { VersionService } from "./service/version.service";
import { SidebarService } from "./service/sidebar-controll.service";
import { TranslateService } from "@ngx-translate/core";
import { Seminar } from "./models/seminar";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Fastbraces";
  public currentUrl: string = window.location.href;
  private _opened: boolean = false;
  private _opened1: boolean = false;
  seminars: Seminar[] = [];

  // Attempt to hide footer for non-doctors
  computeHideFooter = (): boolean => {
    if (this.currentUrl.includes("/providers/view_provider") || (this.currentUrl.includes("/providers"))) {
      return true;
    } else {
      return false;
    }
  }

  computeIsTransparent = (): boolean => {
    if (this.currentUrl.includes("/providers/view_provider")) {
      return false;
    } else if (
      this.currentUrl.includes("/product") ||
      this.currentUrl.includes("/providers")
    ) {
      return true;
    } else {
      return false;
    }
  };

  public _toggleStaffPanel() {
    this.sidebar.addStaffPanelOpened = !this.sidebar.addStaffPanelOpened;
  }

  public _onBackdropClicked(): void {
    console.log("Backdrop clicked");
  }

  saveStaff(): void {
    this.sidebar.closeStaffPanel(false);
  }

  removeStaff(): void {
    this.sidebar.closeStaffPanel(true);
  }

  computeSolidAt = (): number => {
    if (this.currentUrl.includes("/providers/view_provider")) {
      return 0;
    } else if (this.currentUrl.includes("/product")) {
      return 675;
    } else if (this.currentUrl.includes("/providers")) {
      return 200;
    } else {
      return 0;
    }
  };

  transferReg(seminar: Seminar): void {
    this.seminarService.transferRegistration(seminar);
  }

  constructor(
    public router: Router,
    private version: VersionService,
    public sidebar: SidebarService,
    private translate: TranslateService,
    public seminarService: SeminarService,
    private registrationSerivce: RegistrationService
  ) {
    translate.setDefaultLang("en");

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga("set", "page", event.urlAfterRedirects);
        (<any>window).ga("send", "pageview");
        this.currentUrl = window.location.href;
      }
    });
  }

  ngOnInit() {
    this.version.initVersionCheck("https://fastbraces.com/assets/version.json");
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      const element = document.querySelector("#moveTop");
      element.scrollIntoView();
    });
  }
}
