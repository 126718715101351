export class GrowBoneItemModel {
  visible: boolean = false;
  description: string = "";
  imageTitle: string = "";
  day: number = 0;
  imageSrc: string = "";
  visibleAtMinY: number = 0;
  visibleAtMaxY: number = 0;
  marginLeft: number = 0;
  marginTop: number = 0;
  bob: number = 0;

  constructor(
    description: string,
    day: number,
    imageSrc: string,
    imageTitle: string,
    bob: number
  ) {
    this.description = description;
    this.day = day;
    this.imageSrc = imageSrc;
    this.imageTitle = imageTitle;
    this.bob = bob;
  }
}
