import { stringify } from "@angular/compiler/src/util";

export class Registration {
  constructor(
    public first_name?: string,
    public last_name?: string,
    public dentist_email?: string,
    public dentist_cell?: string,
    public practice_number?: string,
    public practice_name?: string,
    public license_number?: string,
    public agd_id?: string,
    public website?: string,
    public address?: string,
    public address2?: string,
    public city?: string,
    public state?: string,
    public zipcode?: string,
    public country?: number,
    public profession?: string,
    public referral?: string,
    public owner?: string,
    public staff_attending?: number,
    public none?: string,
    public aligners_exp?: string,
    public short_term_exp?: string,
    public traditional_exp?: string,
    public courses_id?: number,
    public date_attending?: string,
    public fee_paid?: number,
    public dentist_name?: string,
    public comment?: string,
    public id?: number,
    public country_code?: string,
    public country_id?: string,
    public deleted?: number,
    public registered_status_id?: number,
    public edit_token?: string,
    public country_agreement_letter?: string,
    public attended?: string,
    public records_user_id?: string,
    public sent_to_records?: string,
    public type_course_id?: string,
    public fbstatus?: string,
    public price?: string,
    public special_doctor_type?: string, // for example, an orthodontist from a selected country, who get to see different text.
    public paid_amount?: string
  ) {}

  getRegistrationObject(): IRegistrationObject[] {
    return [
      {
        label: "first_name",
        data: {
          value: "",
          error: false,
          type: 0,
        },
      } as IRegistrationObject,
      {
        label: "last_name",
        data: {
          value: "",
          error: false,
          type: 0,
        },
      } as IRegistrationObject,
      {
        label: "email",
        data: {
          value: "",
          error: false,
          type: 0,
        },
      } as IRegistrationObject,
      {
        label: "mobile",
        data: {
          value: "",
          error: false,
          type: 0,
        },
      } as IRegistrationObject,
      {
        label: "profession",
        data: {
          value: "",
          error: false,
          type: 1,
        },
      } as IRegistrationObject,
      {
        label: "practice_owner",
        data: {
          value: "",
          error: false,
          type: 1,
        },
      } as IRegistrationObject,
    ];
  }

  validateInput() {
    if (!this.first_name) {
      return "Enter a valid first name";
    }
    if (!this.last_name) {
      return "Enter a valid last name";
    }

    const emailPattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z+\-0-9]+\.)+[a-zA-Z+]{2,}))$/;

    this.dentist_email = this.dentist_email.trim();

    if (!emailPattern.test(this.dentist_email)) {
      return "Enter a valid email address";
    }

    if (!this.dentist_cell) {
      return "Enter valid cell / mobile number";
    }

    if (!this.profession) {
      return "Profession is required";
    }

    if (!this.owner) {
      return "Practice owner is required";
    }

    if (!this.practice_name) {
      return "Enter a valid practice name";
    }

    if (!this.practice_number) {
      return "Enter practice number";
    }

    if (!this.address) {
      return "Enter valid Address";
    }

    if (!this.city) {
      return "City is required";
    }

    if (!this.state) {
      return "State is required";
    }

    if (!this.zipcode) {
      return "Zipcode is required";
    }

    if (!this.country) {
      return "Country is required";
    }

    if (!this.referral) {
      return "Referral is required";
    }

    return "";
  }
}

export interface IRegistrationObject {
  label: string;
  data: {
    value: string;
    error: boolean;
    type: InputType;
    dropdown: string[];
  };
}

enum InputType {
  input = 0,
  select = 1,
}
