import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-toaster",
  templateUrl: "./toaster.component.html",
  styleUrls: ["./toaster.component.scss"],
})
export class ToasterComponent implements OnInit {
  constructor() {}
  @Input() errorHasOccured: boolean = false;
  @Input() success: boolean = false;
  @Input() errorToaster: boolean = false;
  @Input() title: string;
  @Input() message: string;
  @Input() errorTitle: string;
  @Input() errorMessage: string;

  ngOnInit(): void {}
}
