import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-before-after",
  templateUrl: "./before-after.component.html",
  styleUrls: ["./before-after.component.scss"],
})
export class BeforeAfterComponent implements OnInit {
  constructor(public router: Router) {}
  ngOnInit(): void {}

  goToMoreCases() {
    this.router.navigateByUrl("/more-cases");
  }
}
