import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() title: string;
  @Input() emphasis: string;
  @Input() disabled: boolean = false;
  @Output() clickEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onClick(event: any): void {
    this.clickEvent.emit(this.title);
  }
}
