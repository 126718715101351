import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-before",
  templateUrl: "./before.component.html",
  styleUrls: ["./before.component.css"],
})

/**
 * "Before and after" images are named like this: [number of days to complete the case]-["BEFORE" or "AFTER"].png.
 * Each set of images should have a "before" and "after" image.
 */
export class BeforeComponent implements OnInit {
  constructor(private meta: Meta, private title: Title) {
    this.title.setTitle("Before & After Pictures | Fastbraces");

    this.meta.addTags([
      {
        name: "description",
        content:
          "View pictures of patients with dental issues that were fixed with Fastbraces. Our technology allows orthodontists to straighten teeth in 120 days or less.",
      },
      {
        name: "keywords",
        content:
          "before and after braces, braces before and after, before after braces, braces before after, before and after picture braces, braces before and after picture, before after picture braces, braces before after picture, before and after pictures braces, braces before and after pictures, before after pictures braces, braces before after pictures",
      },
    ]);
  }

  /**
   * image_details - Details for each set of images: number of days to complete the case, image category, and dentist details.
   */
  image_details = [
    ["152", "Open Bite", "Dr. Melissa Goddard<br>General Dentist"],
    ["120", "Spacing", "Dr. Stephan van Vuuren<br>General Dentist"],
    ["119", "Crowding", "Dr. Melissa Goddard<br>General Dentist"],
    ["117", "Underbite", "Dr. Stephan van Vuuren<br>General Dentist"],
    ["115", "Open Bite", "Dr. Stephan van Vuuren<br>General Dentist"],
    ["96", "Crowding", "Dr. Patrick Assal<br>General Dentist"],
    ["72", "Overbite", "Dr. Melissa Goddard<br>General Dentist"],
    ["65", "Overjet", "Dr. Stephan van Vuuren<br>General Dentist"],
    ["45", "Overjet", "Dr. Stephan van Vuuren<br>General Dentist"],
  ];

  ngOnInit() {}
}
