import { Component, OnInit } from '@angular/core';
//import { AccordionModule } from 'ngx-bootstrap/accordion'

@Component({
  selector: 'app-more-cases',
  templateUrl: './more-cases.component.html',
  styleUrls: ['./more-cases.component.scss']
})
export class MoreCasesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
