import { Component, OnInit } from "@angular/core";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "../../../../service/cookie.service";

@Component({
  selector: "app-cookie-message",
  templateUrl: "./cookie-message.component.html",
  styleUrls: ["./cookie-message.component.css"],
})
export class CookieMessageComponent implements OnInit {
  modalRef: NgbActiveModal;

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public cookieService: CookieService
  ) {}

  ngOnInit() {}

  /**
   * Open the cookie-consent modal IFF the 'cookie-warning' cookie is not set and not expired [otherwise user already consented].
   */
  open() {
    /** Check if cookie is set and not times out. If not set or timed out, open the popup again. */
    if (!this.cookieService.getCookie("cookie-warning")) {
      this.modalRef = this.modalService.open(CookieMessageComponent, {
        backdrop: false,
        keyboard: true,
        windowClass: "cookie-d-block",
      });
    }
  }

  /**
   * Consent to the cookie policy:
   * - Close the cookie consent modal
   * - Set cookie 'cookie-warning' to flag consent.
   * @param closeParam
   */
  consent(closeParam: string) {
    this.cookieService.setCookie("cookie-warning", "true", 40, "/"); // Set cookie to expire in 40 days.
    this.activeModal.close(closeParam);
  }
}
