import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  ElementRef,
} from "@angular/core";

@Component({
  selector: "app-material-input",
  templateUrl: "./mtx-input.component.html",
  styleUrls: ["./mtx-input.component.scss"],
})
export class MtxInputComponent implements OnInit {
  constructor(public elem: ElementRef, public render: Renderer2) {}

  @Input() label: string;
  @Input() inputType: string;
  @Input() pattern: string;
  @Input() errorMessage: string;
  @Output() inputModelChange = new EventEmitter<string>();
  @Input() required: boolean;

  public componentId: number;
  public inputValue: string;
  public hasError: boolean;

  @Input()
  get inputModel() {
    return this.inputValue;
  }

  set inputModel(val) {
    this.inputValue = val;
    if (this.inputValue) {
      const element = this.elem.nativeElement.querySelector(".form-field");
      this.render.addClass(element, "form-field--is-filled");
    }
  }

  checkValue() {
    if (!this.inputValue || this.inputValue === "") {
      this.hasError = true;
    }
  }

  ngOnInit(): void {
    if (!this.inputType) {
      this.inputType = "text";
    }

    this.componentId = Math.floor(Math.random() * 1000000000);
  }
  onChangeEmit(inputValue) {
    this.inputModelChange.emit(inputValue);
  }

  validateInput() {
    if (!this.inputValue && this.required) {
      this.hasError = true;
    } else {
      this.hasError = false;
    }

    if (this.pattern) {
      let re = new RegExp(this.pattern);
      if (!re.test(this.inputValue)) {
        this.hasError = true;
      }
    }
  }

  setInputActive(el, active, eventType): void {
    const formField = el.target.parentNode.parentNode;
    if (active) {
      formField.classList.add("form-field--is-active");
    } else {
      formField.classList.remove("form-field--is-active");
      el.target.value === ""
        ? formField.classList.remove("form-field--is-filled")
        : formField.classList.add("form-field--is-filled");
    }

    if (eventType === "blur") {
      this.validateInput();
    }
  }
}
