import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "app/service/cookie.service";
import { NewCookieMessageComponent } from "./cookie-message/cookie-message.component";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, AfterViewInit {
  date = new Date();
  showCookieMessage: boolean = false;
  constructor(
    public router: Router,
    public cookieComponent: NewCookieMessageComponent,
    public cookieService: CookieService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.cookieService.getCookie("cookie-warning")) {
      this.showCookieMessage = true;
    } else {
      this.showCookieMessage = false;
    }
  }

  getYear(): number {
    return new Date().getFullYear();
  }

  closeCookieModal() {
    this.showCookieMessage = false;
  }

  openLogin(): void {
    window.location.replace("https://www.fastbracesonline.com");
  }

  openYoutube(): void {
    window.location.replace("https://www.youtube.com/user/FastbracesUSA");
  }
  openFacebook(): void {
    window.location.replace("https://www.facebook.com/FastbracesTechnology/");
  }
  openInstagram(): void {
    window.location.replace("https://instagram.com/Fastbracestechnologies");
  }
  openTikTok(): void {
    window.location.replace("https://www.tiktok.com/@fastbraces?_t=8ff9wtFpJpC&_r=1");
  }
  openTwitter(): void {
    window.location.replace("https://twitter.com/FastbracesTech");
  }
}
