import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Registration } from "../../../models/registration";
import { Country } from "../../../models/country";
import { AlertService } from "../../../service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RegistrationService } from "../../../service/registration.service";
import { CountryService } from "../../../service/country.service";

@Component({
  selector: "app-verify-information",
  templateUrl: "./verify-information.component.html",
  styleUrls: ["./verify-information.component.css"],
})
export class VerifyInformationComponent implements OnInit {
  @ViewChild("cardInfo") cardInfo: ElementRef;
  @Input() registration: Registration;
  countries: Country[];

  /***
   *
   * MAKE SERVICES FOR THESE
   * @type {{profession: string}[]}
   */
  professions: Profession[] = [
    { profession: "General Dentist" },
    { profession: "Orthodontist" },
    { profession: "Dental Hygienist" },
    { profession: "Staff Member" },
    { profession: "Pedodontist" },
    { profession: "Prosthodontist" },
    { profession: "Periodontist" },
    { profession: "Oral Surgeon" },
    { profession: "Endodontist" },
  ];

  /*
  referrals: Referral[] = [
    // { source: "Digital Ad" },
    { source: "Digital Ad" },
    { source: "Doctor Referral" },
    { source: "Email" },
    { source: "Facebook" },
    // { source: "Google" },
    { source: "Instagram" },
    { source: "Internet Search" },
    { source: "LinkedIn" },
    { source: "Magazine" },
    // { source: "Chrisad" },
    // { source: "Phone Call" },
    { source: "Postcard" },
    { source: "Press Release" },
    { source: "Twitter" },
    { source: "YouTube" },
  ];
  */
  referrals: Referral[] = [
    { source: "Social Media" },
    { source: "Dentaltown Magazine" },
    { source: "The Profitable Dentist" },
    { source: "Doctor Referral" },
    { source: "Email" },
    { source: "Postcard" },
    { source: "Letter" },
    // { source: "Text Message" },
    // { source: "FedEx Letter" },
    { source: "Internet Search" },
    // { source: "JCO Magazine" },
    // { source: "Letter" },
    // { source: "Orthotown Magazine" },
    // { source: "TikTok" },
    { source: "Chrisad" },
  ];
  owners: Owner[] = [{ status: "Yes" }, { status: "No" }];

  constructor(
    private route: ActivatedRoute,
    private registrationService: RegistrationService,
    private countryService: CountryService,
    private modalService: NgbModal,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.countryService
      .getCountries()
      .subscribe((countries) => (this.countries = countries));
  }

  /**
   * Open NgbModal modal
   */
  openModal(content): void {
    this.modalService.open(content);
  }

  /**
   *
   * Calls to update current registration with newly entered
   * values.
   */
  updateRegistration(): void {
    /** Field that can no longer be sent and are not needed on this page */
    delete this.registration["staff_attending"];
    delete this.registration["fee_paid"];

    this.registrationService.putRegistration(this.registration).subscribe(
      (registration) => {
        this.registration = registration;
      },
      (error) => {
        window.scrollTo(0, 0);
        this.alertService.error(error.error);
      },
      () => {
        window.scrollTo(0, 0);
        this.alertService.success("Successfully updated doctor information");
      }
    );
  }
}

//for type safety
interface Profession {
  profession: string;
}
interface Referral {
  source: string;
}
interface Owner {
  status: string;
}
