import { Injectable } from "@angular/core";

/**
 * Cookie Helper
 */

@Injectable()
export class CookieService {
  constructor() {}

  /**
   * Cookie helper (get)
   * @param name : name of the cookie to search document for.
   * @returns {string} : value of the cookie with specified name, empty string otherwise.
   */
  public getCookie(name: string): string {
    let cookieArray: Array<string> = document.cookie.split(";");
    let cookieName = `${name}=`;
    let cookieArraySubstring: string; //part of the split cookieArray

    for (
      let partCounter: number = 0;
      partCounter < cookieArray.length;
      partCounter += 1
    ) {
      cookieArraySubstring = cookieArray[partCounter].replace(/^\s+/g, "");

      if (cookieArraySubstring.indexOf(cookieName) == 0) {
        return cookieArraySubstring.substring(
          cookieName.length,
          cookieArraySubstring.length
        );
      }
    }

    return "";
  }

  /**
   * Cookie helper (set)
   * @param name
   * @param value
   * @param expireDays
   * @param path
   */
  public setCookie(
    name: string,
    value: string,
    expireDays: number,
    path: string = ""
  ) {
    let theDate: Date = new Date();
    theDate.setTime(theDate.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = "expires=" + theDate.toUTCString();
    document.cookie =
      name +
      "=" +
      value +
      "; " +
      expires +
      (path.length > 0 ? "; path=" + path : "");
  }

  /**
   * Cookie helper (delete)
   * @param name : name of the cookie to delete from document.
   */
  public deleteCookie(name) {
    this.setCookie(name, "", -1);
  }
}
