import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { CookieService } from "../../../service/cookie.service";

@Component({
  selector: "app-new-cookie-message",
  templateUrl: "./cookie-message.component.html",
  styleUrls: ["./cookie-message.component.scss"],
})
export class NewCookieMessageComponent implements OnInit {
  @Output() okayClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor(public cookieService: CookieService) {}

  ngOnInit() {}

  consent(closeParam: string) {
    this.cookieService.setCookie("cookie-warning", "true", 360, "/"); // Set cookie to expire in ### days.
    this.okayClicked.emit(closeParam);
  }
}
