import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Country } from "../models/country";
import { environment } from "../../environments/environment";
import { ModelService } from "./model.service";
@Injectable()
export class CountryService extends ModelService<Country> {
  private countriesUrl = environment.apiUrl + "country";

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * GET countries from server
   * @returns {Observable<Country>}
   */
  getCountries(): Observable<Country[]> {
    return this.get(this.countriesUrl, null);
  }

  /**
   * GET countries from server ONLY where there is a seminar
   * @returns {Observable<Object>}
   */
  getSeminarCountries(): Observable<Country[]> {
    return this.get(this.countriesUrl + "/true");
  }
}
