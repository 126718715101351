import { Component, OnInit, Input } from "@angular/core";
import { Registration } from "../../../models/registration";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-preview-agreement",
  templateUrl: "./preview-agreement.component.html",
  styleUrls: ["./preview-agreement.component.css"],
})
export class PreviewAgreementComponent implements OnInit {
  @Input() registration: Registration;
  agreementUrl: string;

  constructor() {}

  ngOnInit() {
    this.insertAgreementFrame();
  }

  setAgreementUrl() {
    //todo: use the best of the 2 following options:
    //<!--src="https://drive.google.com/viewerng/viewer?embedded=true&url=http://devapi.fastbraces.com/web_services/agreement/sample/A/27uld2ahe4s0gomfnqhh"-->
    //<!--src="http://devapi.fastbraces.com/web_services/agreement/sample/A/27uld2ahe4s0gomfnqhh#toolbar=0&navpanes=0&scrollbar=0">-->

    this.agreementUrl =
      environment.apiUrl +
      "agreement/sample/" +
      this.registration.country_agreement_letter +
      "/" +
      this.registration.edit_token +
      "/#toolbar=0&navpanes=0&scrollbar=0";
  }

  onRightClick(event) {
    return false;
  }

  insertAgreementFrame() {
    this.setAgreementUrl();
    var agreementIframe = document.createElement("iframe");
    agreementIframe.setAttribute("class", "pdf-agreement-preview");
    agreementIframe.setAttribute("frameborder", "0");
    //agreementIframe.setAttribute("oncontextmenu", "onRightClick($event)");
    //agreementIframe.setAttribute("contextmenu", "onRightClick($event)");
    agreementIframe.setAttribute("src", this.agreementUrl);
    document.getElementById("add-iframe-here").appendChild(agreementIframe);
  }
}
