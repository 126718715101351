import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-recent-publications",
  templateUrl: "./recent-publications.component.html",
  styleUrls: ["./recent-publications.component.scss"],
})
export class RecentPublicationsComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}

  goToPubPage() {
    this.router.navigateByUrl("/publications");
  }
}
