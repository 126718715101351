import { Component, OnInit } from "@angular/core";
import { SeminarService } from "../../../service/seminar.service";
import { Seminar } from "../../../models/seminar";
import { Country } from "../../../models/country";
import { State } from "../../../models/state";
import { CountryService } from "../../../service/country.service";
import { StatesService } from "../../../service/states.service";
import { Meta, Title } from "@angular/platform-browser";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AlertService } from "../../../service/alert.service";

@Component({
  selector: "app-seminars",
  templateUrl: "./seminars.component.html",
  styleUrls: ["./seminars.component.css"],
})
export class SeminarsComponent implements OnInit {
  /** Seminars to be displayed */
  seminars: Seminar[];
  /** All seminars in the selected 'country' (to be filtered by 'state' without re-fetching from backend) */
  allCountrySeminars: Seminar[];
  /** Countries that have seminars open to clients */
  countries: Country[];
  /** States that have open seminars, specific to the selected country (currently only enabled for USA states) */
  states: State[];

  selectedCountry: number;
  isSpanish: boolean;
  selectedState: string;
  loading: boolean =
    false; /* Flag, visual feedback to let users know they have to wait */

  constructor(
    private seminarService: SeminarService,
    private countryService: CountryService,
    private statesService: StatesService,
    private meta: Meta,
    private title: Title,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {
    this.title.setTitle("Orthodontics Seminars for Dentists | Fastbraces");

    this.meta.addTags([
      {
        name: "description",
        content:
          "Receive free training on Fastbraces for practice owners. View our upcoming orthodontics seminars for dentists.",
      },
      {
        name: "keywords",
        content:
          "seminars in orthodontics, braces seminars, orthodontic seminars, ortho seminars, orthodontics seminar, seminars orthodontics, seminar orthodontics, seminar in orthodontics, semiunars braces, seminar braces, braces seminar",
      },
    ]);
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.selectedCountry = 226; /** Initialize the country dropdown */
    this.selectedState = "all"; /** Initialize the state dropdown */
    this.loading = true;
    this.getCountries();
    this.getSeminars();
    this.getStates();
  }

  getSeminars(): void {
    //if there's an additional url component, this may be a country id, we want to get the seminars for that country
    if (this.route.snapshot.paramMap.get("countryId")) {
      this.seminarService
        .getSeminarsByCountry(+this.route.snapshot.paramMap.get("countryId"))
        .subscribe(
          (seminars) => {
            this.loading = false;
            this.allCountrySeminars = this.seminars = seminars;
          },
          (error) => {
            this.loading = false;
            this.alertService.error("No seminars found for selected country");
          }
        );
      return;
    } else {
      //otherwise let's default to getting US seminars
      this.seminarService.getSeminarsByCountry(226).subscribe((seminars) => {
        this.allCountrySeminars = this.seminars = seminars;
        this.loading = false;
      });
      return;
    }
  }

  getCountries(): void {
    this.countryService
      .getSeminarCountries()
      .subscribe((countries) => (this.countries = countries));
  }

  /**
   * get all US states with seminars
   */
  getStates(): void {
    this.statesService
      .getSeminarStates()
      .then((states) => (this.states = states))
      .then(() => this.statesCallback());
  }

  /**
   * Add the first 'ALL STATES' element to states array
   */
  statesCallback(): void {
    this.states.unshift({
      country_code: "US",
      state_code: "all",
      state_name: "ALL STATES",
    });
  }

  /**
   * Get seminars for specific state only
   * @param selectedValue - input state from 'stateForm'
   */
  setState(selectedValue: NgForm) {
    var selectedState = (this.selectedState =
      selectedValue.value.selectedState);

    /** Use all states seminars for the selected country */
    if (selectedState == "all") {
      this.seminars = this.allCountrySeminars;
      return;
    }

    /** Filter seminars by state [element = value of this entry, index = index, seminar=all seminars list] */
    this.seminars = this.allCountrySeminars.filter(function (
      element,
      index,
      seminar
    ) {
      var dc_array = ["DC", "MD", "VA"];
      var nj_array = ["NY", "NJ"];

      if (dc_array.includes(selectedState)) {
        /** State is or close to DC */
        return (
          element.state == "DC" ||
          element.state == "MD" ||
          element.state == "VA"
        );
      } else if (nj_array.includes(selectedState)) {
        /** State is or close to NJ */
        return element.state == "NJ" || element.state == "NY";
      } else {
        /** Normal state (not too close to DC or NJ)*/
        return element.state == selectedState;
      }
    });
  }

  /**
   *
   * Updates the seminar table with new values based on user selected country
   * @param selectedValue
   */
  updateSeminars(selectedValue: NgForm): void {
    this.loading = true;

    //set property to user selected country value
    this.selectedCountry = selectedValue.value.selectedCountry;

    /** In case this is US, initialize state */
    this.selectedState = "all";

    //replace contents of seminars array with new results
    this.seminarService.getSeminarsByCountry(this.selectedCountry).subscribe(
      /** Fetch seminars by country */
      (seminars) => {
        this.allCountrySeminars = this.seminars = seminars;
        this.loading = false;
      }
    );

    //check to see if the selected country is mexico so we can determine which template to use
    for (let country of this.countries) {
      if (this.selectedCountry === country["country_id"]) {
        if (
          country["nicename"] == "Mexico" ||
          country["nicename"] == "Spain" ||
          country["nicename"] == "Colombia" ||
          country["nicename"] == "Chile"
        ) {
          this.isSpanish = true;
        } else {
          this.isSpanish = false;
        }
      }
    }
  }
}
