import { WindowRef } from "./injectables/window-ref";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppComponent } from "./app.component";

import { HeaderComponent } from "./components/header_new/header.component";
import { SeminarsComponent } from "./pages/old_pages/seminars/seminars.component";

import { SeminarService } from "./service/seminar.service";
import { AppRoutingModule } from "./app-routing.module";

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { HomeComponent } from "./pages/home/home.component";

import { TestComponent } from "./pages/test/test.component";

import { ProductComponent } from "./pages/product/product.component";
import { PublicationsComponent } from "./pages/publications/publications.component";

import { BiologyComponent } from "./pages/old_pages/biology/biology.component";
import { WorksComponent } from "./pages/old_pages/works/works.component";

import { BeforeComponent } from "./pages/old_pages/before/before.component";
import { SupportComponent } from "./pages/old_pages/support/support.component";

import { HonorsComponent } from "./pages/old_pages/honors/honors.component";
import { CertificatesComponent } from "./pages/certificates/certificates.component";

import { TermsComponent } from "./pages/terms/terms.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { HistoryComponent } from "./pages/old_pages/history/history.component";

import { FooterComponent } from "./components/footer_new/footer.component";
import { NewCookieMessageComponent } from "./components/footer_new/cookie-message/cookie-message.component";
import { CookieMessageComponent } from "./pages/old_pages/footer/cookie-message/cookie-message.component"; // For footer cookie

import { FastResultsComponent } from "./pages/product/fast-results/fast-results.component";

import { SlideCompareComponent } from "./components/slideCompare/slide.compare.component";
import { EmptySlideCompareComponent } from "./components/empty-slideCompare/empty-slide.compare.component";

import { FormsModule } from "@angular/forms";

import { RegistrationComponent } from "./pages/registration/registration.component";
import { AlertComponent } from "./directives/index";

import { AlertService } from "./service/index";
import { RegistrationService } from "./service/registration.service";

import { ProviderService } from "./service/provider.service";
import { ProvidersComponent } from "./pages/old_pages/providers/providers.component";

import { AgmCoreModule } from "@agm/core";
import { MapComponent } from "./pages/old_pages/providers/map/map.component";

import { CountryService } from "./service/country.service";
import { StatesService } from "./service/states.service";
import { LecturesService } from "./service/lectures.service";

import { NgxPaginationModule } from "ngx-pagination";
import { ViewProviderComponent } from "./pages/old_pages/providers/view-provider/view-provider.component";

import { CeCreditService } from "./service/ce-credit.service";
import { EditRegistrationComponent } from "./pages/registration/edit-registration/edit-registration.component";
import { AddStaffComponent } from "./pages/registration/edit-registration/add-staff/add-staff.component";
import { ImageUploadComponent } from "./pages/registration/edit-registration/image-upload/image-upload.component";
import { TransferRegistrationComponent } from "./pages/old_pages/registration/edit-registration/transfer-registration/transfer-registration.component";
import { SeminarPaymentComponent } from "./pages/old_pages/registration/seminar-payment/seminar-payment.component";
import { PaymentService } from "./service/payment.service";
import { AgreementPaymentService } from "./service/agreement-payment.service";
import { CookieService } from "./service/cookie.service";

import { FacebookModule } from "ngx-facebook";
import { VersionService } from "./service/version.service";
import { SidebarService } from "./service/sidebar-controll.service";
import { ResourceComponent } from "./pages/old_pages/resources/resource/resource.component";
import { RecommendationsComponent } from "./pages/old_pages/recommendations/recommendations.component";
import { ComparisonComponent } from "./pages/old_pages/comparison/comparison.component";

import { AgreementComponent } from "./pages/agreement/agreement.component";
import { AddCcComponent } from "./pages/agreement/add-cc/add-cc.component";
import { VerifyInformationComponent } from "./pages/agreement/verify-information/verify-information.component";
import { PreviewAgreementComponent } from "./pages/agreement/preview-agreement/preview-agreement.component";

import { CarouselModule } from "primeng/carousel";
import { SearchProvidersComponent } from "./pages/search-providers/providers.component";
import { ViewSearchedProviderComponent } from "./pages/search-providers/view-provider/view-provider.component";
import { SearchMapComponent } from "./pages/search-providers/map/map.component";
import { WhyComponent } from "./pages/old_pages/why/why.component";
import { ButtonComponent } from "./components/button/button.component";
import { InputComponent } from "./components/input/input.component";
import { PhonePipe } from "./pipes/phone";
import { BeforeAfterComponent } from "./pages/before-after/before-after.component";
import { VideoPlayerComponent } from "./components/video-player/video-player.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { SelectComponent } from "./components/select/select.component";

import { EmbedVideo } from "ngx-embed-video";
import { MtxInputComponent } from "./components/mtx-input/mtx-input.component";
import { MtxSelectComponent } from "./components/mtx-select/mtx-select.component";
import { SidebarModule } from "ng-sidebar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MatInputModule } from "@angular/material/input";
import { MatSidenavModule } from "@angular/material/sidenav";
import { SidePanelComponent } from "./components/side-panel/side-panel.component";
import { StaffService } from "./service/staff.service";
import { ToasterComponent } from "./components/toaster/toaster.component";
import { SeminarComponent } from "./pages/seminar/seminar.component";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ReactiveFormsModule } from "@angular/forms";
import { CampusComponent } from "./pages/campus/campus.component";
import { SafePipe } from "./pipes/safe.pipe";
import { GrowBoneComponent } from "./pages/product/grow-bone/grow-bone.component";
import { RecentPublicationsComponent } from "./pages/recent-publications/recent-publications.component";
import { RecentListComponent } from "./pages/recent-publications/recent-list/recent-list.component";
import { MediaComponent } from "./pages/media/media.component";
import { MediaListComponent } from "./pages/media/media-list/media-list.component";
import { VideosListComponent } from "./pages/media/videos-list/videos-list.component";
import { MoreCasesComponent } from "./pages/more-cases/more-cases.component";
import { DoctorSupportComponent } from "./pages/doctor-support/doctor-support.component";
import { DentaltownComponent } from "./pages/dentaltown/dentaltown.component";
import { DentaltownArticlesComponent } from "./pages/dentaltown/dentaltown-articles/dentaltown-articles.component";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProductComponent,
    PublicationsComponent,
    SeminarsComponent,
    HomeComponent,
    TestComponent,
    BiologyComponent,
    WorksComponent,
    BeforeComponent,
    SupportComponent,
    HonorsComponent,
    CertificatesComponent,
    ContactComponent,
    TermsComponent,
    FooterComponent,
    HistoryComponent,
    RegistrationComponent,
    AlertComponent,
    ProvidersComponent,
    MapComponent,
    ViewProviderComponent,
    EditRegistrationComponent,
    AddStaffComponent,
    ImageUploadComponent,
    TransferRegistrationComponent,
    SeminarPaymentComponent,
    ResourceComponent,
    RecommendationsComponent,
    ComparisonComponent,
    AgreementComponent,
    AddCcComponent,
    VerifyInformationComponent,
    PreviewAgreementComponent,
    SearchProvidersComponent,
    ViewSearchedProviderComponent,
    SearchMapComponent,
    WhyComponent,
    CookieMessageComponent,
    NewCookieMessageComponent,
    ButtonComponent,
    SlideCompareComponent,
    EmptySlideCompareComponent,
    InputComponent,
    SelectComponent,
    PhonePipe,
    FastResultsComponent,
    BeforeAfterComponent,
    VideoPlayerComponent,
    CarouselComponent,
    MtxInputComponent,
    MtxSelectComponent,
    SidePanelComponent,
    ToasterComponent,
    SeminarComponent,
    CampusComponent,
    SafePipe,
    GrowBoneComponent,
    RecentPublicationsComponent,
    RecentListComponent,
    MediaComponent,
    MediaListComponent,
    VideosListComponent,
    MoreCasesComponent,
    DoctorSupportComponent,
    DentaltownComponent,
    DentaltownArticlesComponent,
  ],
  entryComponents: [
    CookieMessageComponent, // For footer cookie
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    CarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    EmbedVideo.forRoot(),
    SidebarModule.forRoot(),
    AgmCoreModule.forRoot(
      // {apiKey:'AIzaSyAf41Vh9MadHDTWs5FPOnhwHaN5g3Afooo'}
      { apiKey: "AIzaSyCj_ipaJbqabzYIkwvVfZcGzR5ShKo6w3c" }
    ),
    FacebookModule.forRoot(),
    NgxPaginationModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSidenavModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    CookieMessageComponent, // For footer cookie - only 1 instance for the whole app
    NewCookieMessageComponent,
    NgbActiveModal, // For footer cookie
    CookieService, // For footer cookie
    SeminarService,
    AlertService,
    RegistrationService,
    ProviderService,
    CountryService,
    LecturesService,
    CeCreditService,
    PaymentService,
    AgreementPaymentService,
    StatesService,
    VersionService,
    SidebarService,
    StaffService,
    WindowRef,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
