import { Component, OnInit } from "@angular/core";
import { CeCreditService } from "../../service/ce-credit.service";

import { Ce_credit } from "../../models/ce_credit";
import { AlertService } from "../../service/alert.service";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

@Component({
  selector: "app-certificates",
  templateUrl: "certificates.component.html",
  styleUrls: ["certificates.component.scss"],
})
export class CertificatesComponent implements OnInit {
  firstName: string;
  lastName: string;
  credits: Ce_credit[];

  selectedAttendant: Ce_credit;
  enteredAgd: string;
  enteredLicense: string;

  constructor(
    private ceService: CeCreditService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {}

  getCeCredits(): void {
    if (!this.firstName || !this.lastName) {
      this.alertService.error("Please Enter BOTH a first name and a last name");
      return;
    }
    this.ceService.getCeCredits(this.firstName, this.lastName).subscribe(
      (credits) => (this.credits = credits),
      (Error) => {
        this.alertService.error("Record not found. Please try again.");
      },
      () => this.ceSearchResultsCallBack()
    );
  }

  console(): void {
    console.log(this.credits);
  }

  /** Opens an NgbModal modal */
  openModalAndBindAttendant(content: string, attendant: Ce_credit) {
    this.selectedAttendant = attendant;
    this.modalService.open(content).result.then();
  }

  /**
   * Initiates CE credit document download
   */
  downloadCePdf(): void {
    this.refreshAttendantAgdAndLicense();

    this.ceService.downloadCeCredits(this.selectedAttendant).subscribe(
      (res) => {
        //create a new blob and set the mime type to the expected pdf
        var blob = new Blob([res], { type: "application/pdf" });
        const fileName: string = "certificate.pdf";
        const pdfUrl = URL.createObjectURL(blob);
        //we need to create a new element in the DOM to house the download
        const downloadAnchor = document.createElement("a") as HTMLAnchorElement;
        downloadAnchor.href = pdfUrl;
        downloadAnchor.download = fileName;
        document.body.appendChild(downloadAnchor);
        //finally we simulate a click on the element to initiate the download
        downloadAnchor.click();
        this.alertService.success(
          "Your certificate has been downloaded. If the download has not started, please try again after disabling any popup blockers.",
          true
        );
        this.router.navigate([""]);
      },
      (error) => {
        this.alertService.warn(
          "Unable to download certificate for this entry."
        );
      }
    );
  }

  /**
   * Callback function for searching for CE by entered name.
   * We will display a warning if the result set is empty
   */
  ceSearchResultsCallBack(): void {
    if (!this.credits.length) {
      this.alertService.warn(
        "Unable to locate any attendants with the entered first and last name"
      );
    }
  }

  /**
   * Updates currently selected CE_credit option
   * with newly entered values
   */
  refreshAttendantAgdAndLicense(): void {
    this.selectedAttendant.license_number = this.enteredLicense;
    this.selectedAttendant.agd_id = this.enteredAgd;
  }
}
