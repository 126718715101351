import { Component, OnInit } from "@angular/core";
import { ProviderService } from "../../service/provider.service";

import { Provider } from "../../models/provider";

import { Country } from "../../models/country";
import { CountryService } from "../../service/country.service";

import { ActivatedRoute, Params } from "@angular/router";

import { Meta, Title } from "@angular/platform-browser";

import { PhonePipe } from "../../pipes/phone";
import { KeyValuePairModel } from "../../components/select/models/keyvaluepair.model";

import { AgmGeocoder, MapsAPILoader } from "@agm/core";

import { CookieService } from "../../service/cookie.service";

@Component({
  selector: "app-providers",
  templateUrl: "./providers.component.html",
  styleUrls: ["./providers.component.scss"],
})
export class SearchProvidersComponent implements OnInit {
  lat: number = 39;
  lng: number = -98;

  base: baseLngAndLat = { lat: 39, lng: -98 };

  providers: Provider[];
  countries: KeyValuePairModel[];

  executiveSpeakers = []; // executiveSpeakers = [3974,382,595,596,2765,1403,1330,95,605,327,421];

  zip: string;
  country: KeyValuePairModel; /* Initialize country to USA */
  distance: KeyValuePairModel;
  name: string;
  errorMessage: string;

  sending: boolean = false; /* Flag that data sending is in progress */

  cookieService: CookieService = new CookieService();
  searchProviderCookieKey = "providers-search";

  validDistances: KeyValuePairModel[] = [
    { key: "5", value: 5 },
    { key: "15", value: 15 },
    { key: "25", value: 25 },
    { key: "50", value: 50 },
    { key: "75", value: 75 },
    { key: "100", value: 100 },
  ];

  constructor(
    private providerService: ProviderService,
    private countryService: CountryService,
    private meta: Meta,
    private title: Title,
    private route: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.title.setTitle("Find an Authorized Provider | Fastbraces");

    this.meta.addTags([
      {
        name: "description",
        content:
          "To find an authorized Fastbraces provider near you, use our Find a Dentist feature. Dentists across the globe are becoming providers daily.",
      },
    ]);
  }

  ngOnInit() {
    this.getCountries();
    this.distance = this.validDistances.find((t) => t.value === 100);
    this.errorMessage = this.route.snapshot.paramMap.get("error");
  }

  /**
   * Gets providers within entered distance from zip
   */
  getProviders(): void {
    this.sending = true;

    this.providerService
      .getProviders(
        this.zip,
        this.country.value.country_id,
        this.distance.value
      )
      .subscribe(
        (provider) => (this.providers = provider),
        (error) => {
          this.sending = false;
          this.errorMessage =
            "No providers found for the entered country and zip code";
          window.scrollTo(0, 0);
          return false;
        },
        () => (this.sending = false)
      );
    this.getBaseCoords();
    this.cookieService.setCookie(
      this.searchProviderCookieKey,
      JSON.stringify({
        zip: this.zip,
        country_id: this.country.value.country_id,
        distance: this.distance.value,
      }),
      1
    );
  }

  /**
   *
   * Returns origin point lng and lat for the enetered address.
   * Used to recenter google map
   */
  getBaseCoords(): void {
    this.providerService
      .getBaseLngAndLat(this.zip, this.country.value.country_id)
      .subscribe((base) => {
        this.base = base;
      });
  }

  /**
   * Should probably make countries its own component, we've called it a lot
   * so far
   */
  getCountries(): void {
    this.countryService.getCountries().subscribe((countries) => {
      this.countries = countries.map(
        (t: any) => new KeyValuePairModel(t.name, t)
      );
      this.country = this.countries.find((t) => t.value.country_id === "226");

      // After we load countries, we should try to auto-complete the zip code and get results with default values.
      if (this.cookieService.getCookie(this.searchProviderCookieKey)) {
        const cookieData = JSON.parse(
          this.cookieService.getCookie(this.searchProviderCookieKey)
        );
        this.zip = cookieData.zip;
        this.distance = this.validDistances.find(
          (t) => t.value === Number(cookieData.distance)
        );
        this.country = this.countries.find(
          (t) => t.value.country_id === cookieData.country_id
        );
        this.getProviders();
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          const geocoder = new AgmGeocoder(this.mapsAPILoader);
          geocoder
            .geocode({
              location: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            })
            .subscribe((results) => {
              const address = results[0].address_components;
              this.zip = address[address.length - 2].long_name;
              this.getProviders();
            });
        });
      }
    });
  }

  getProviderByName(): void {
    this.sending = true;
    window.scrollTo(100, 300);
    this.providerService
      .getProvidersByName(this.uriEncode(this.name))
      .subscribe(
        (provider) => (this.providers = provider),
        (error) => {
          this.sending = false;
          this.errorMessage = "Unable to locate any providers using that name";
          window.scrollTo(0, 0);
          return false;
        },
        () => (this.sending = false)
      );
  }

  /**
   * Determines which logo to display in provider results
   * @param seniorMaster
   * @param master
   * @returns string
   */
  // getProviderLogo(seniorMaster: number, master: number): string {
  getProviderLogo(seniorMaster: number, master: number, profession: string, special_orthodontist_country: string ): string {

    if ((profession == "1") && (special_orthodontist_country == "is_special_orthodontist_country")) {
        return "../../assets/images/provider/fb_logo_provider.png";
    }

    if (seniorMaster == 1) {
      return "../../assets/images/provider/SMA_provider.png";
    }
    if (master == 1) {
      return "../../assets/images/provider/MA_provider.png";
    }

    return "../../assets/images/provider/fb_logo_provider.png";
  }

  /**
   * URI encode an input string
   */
  uriEncode(inputUri: string): string {
    return encodeURIComponent(inputUri);
  }

  /**
   * @param inputName - input string
   * @returns {string} - string after removing substring starting with '('
   * Remove anything at and after "(" for doctor location URL, and return the resulting string
   */
  removeBrackets(inputName: string): string {
    if (inputName.indexOf("(") !== -1) {
      return inputName.substring(0, inputName.indexOf("("));
    } else {
      return inputName;
    }
  }

  // --------Begin space fillers:
  getYear(): number {
    return new Date().getFullYear();
  }
  openYoutube(): void {
    window.location.replace("https://www.youtube.com/user/FastbracesUSA");
  }
  openFacebook(): void {
    window.location.replace("https://www.facebook.com/FastbracesTechnology/");
  }
  openInstagram(): void {
    window.location.replace("https://instagram.com/Fastbracestechnologies");
  }
  openTikTok(): void {
    window.location.replace("https://www.tiktok.com/@fastbraces?_t=8ff9wtFpJpC&_r=1");
  }
  openTwitter(): void {
    window.location.replace("https://twitter.com/FastbracesTech");
  }
  // --------End space fillers.


}

interface distance {
  distance: number;
}

interface baseLngAndLat {
  lat: number;
  lng: number;
}
