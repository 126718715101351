import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dentaltown-articles",
  templateUrl: "./dentaltown-articles.component.html",
  styleUrls: ["./dentaltown-articles.component.scss"]
})
export class DentaltownArticlesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.publications = this.publications.sort((a, b) => this.compareDates(b.publishDate, a.publishDate));
    // console.log(this.publications);
  }

  openPublicationLink(url): void {
    window.open(url, "_blank");
  }

  publications: Array<{ publisher?: string; title: string; publishDate?: string; link: string }> = [
    // JDHODT-14-00607
    {
      publisher: "Dentaltown",
      publishDate:  "January 2025",
      //title: "Fastbraces®",
      title: "Fastbraces®: Doctor-to-doctor support for dentists worldwide",
      link: "/assets/dentaltown/DT0125_Doctor_support_Jan_2025.pdf",
    },

    {
      publisher: "Dentaltown",
      publishDate:  "February 2023",
      //title: "Fastbraces",
      title: "Fastbraces: Finally, a technology that helps dentists move teeth really fast, safely",
      link: "/assets/dentaltown/DT0223_Feb_2023.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "February 2025",
      title: "Fastbraces®: Doctor-to-doctor support for dentists worldwide",
      link: "/assets/dentaltown/DT0225_Feb_2025.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "March 2024",
      title: "Fastbraces: Prediction of orthodontic treatment in days",
      link: "/assets/dentaltown/DT0324_PP_March_2024.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "May 2023",
      title: "Fastbraces: Changing the financial landscape of orthodontics in dentistry",
      link: "/assets/dentaltown/DT0523_PP_May_2023.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "June 2024",
      title: "Fastbraces: The next generation of orthodontic movement in days",
      link: "/assets/dentaltown/DT0624_PP_June_2024.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "July 2024",
      title: "Fastbraces: Dentaltown July TCA Showcase",
      link: "/assets/dentaltown/DT0724_TCA_Showcase-Fastbraces_July_2024.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "August 2023",
      title: "Fastbraces: The cupping depth of the alveolar bone",
      link: "/assets/dentaltown/DT0823_PP_Aug_2023.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "September 2024",
      title: "Fastbraces: Making spaces for implants in about 100 days",
      link: "/assets/dentaltown/DT0924_PP_Sept_2024.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "October 2023",
      title: "Fastbraces: The technology developed to move teeth fast",
      link: "/assets/dentaltown/DT1023_PP_Oct_2023.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "October 2024",
      title: "Fastbraces®: Patented method of moving teeth fast, properly",
      link: "/assets/dentaltown/DT1024_Oct_2024.pdf",
    },

    {
      publisher: "Dentaltown",
      publishDate:  "November 2024",
      title: "Fastbraces®: Moving teeth fast properly at the dental office",
      link: "/assets/dentaltown/DT1124_Nov_2024.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "Novermber 2024",
      title: "Nonsurgical Underbite Treatment",
      link: "/assets/dentaltown/DT1124_Viazis_Fastbraces_case_Nov_2024.pdf",
    },
    {
      publisher: "Dentaltown",
      publishDate:  "December 2024",
      title: "Fastbraces: History: 30 Years in the Making",
      link: "/assets/dentaltown/DT1224_PP_Dec_2024.pdf",
    },
    {
      publisher: "Orthotown",
      publishDate:  "June 2023",
      title: "Fastbraces: A patented method of alveolar bone restoration",
      link: "/assets/dentaltown/Orthotown_0623_PP_June_2023.pdf",
    },
];




compareDates(date1: string, date2: string): number {
  const months = {
    "January": 1, "February": 2, "March": 3, "April": 4,
    "May": 5, "June": 6, "July": 7, "August": 8,
    "September": 9, "October": 10, "November": 11, "December": 12
  };

  const parseDate = (date: string) => {
    if (!date) { return { year: 0, month: 0 }; } // Handle missing dates
    const parts = date.split(" ");
    if (parts.length === 2) {
      return { year: parseInt(parts[1], 10), month: months[parts[0]] || 0 };
    }

    return { year: 0, month: 0 };
  };

  const d1 = parseDate(date1);
  const d2 = parseDate(date2);

  return d1.year !== d2.year ? d1.year - d2.year : d1.month - d2.month;
}


}


