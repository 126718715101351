import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { Registration } from "../../../models/registration";
import { NgForm } from "@angular/forms";
import { AlertService } from "../../../service";
import { Router } from "@angular/router";
import { RegistrationService } from "../../../service/registration.service";
import { AgreementPaymentService } from "../../../service/agreement-payment.service";

//For previewing agreement:
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-add-cc",
  templateUrl: "./add-cc.component.html",
  styleUrls: ["./add-cc.component.scss"],
})
export class AddCcComponent implements OnInit {
  @ViewChild("cardInfo", { static: true }) cardInfo: ElementRef;

  @Input() registration: Registration;
  @Output()
  sendingEvent: EventEmitter<any> =
    new EventEmitter<any>(); /* Flag that data sending is in progress */

  card: any;
  error: string;
  cardHandler = this.onChange.bind(this);
  totalAmountDue: number;
  domainCostMonthly: number;
  sending: boolean = false; /* Flag that data sending is in progress */
  //displayCheckout: boolean = true;

  /** If they want to rent any domains */
  requestedDomain_1: string = "";
  requestedDomain_2: string = "";
  requestedDomain_3: string = "";
  requestedDomain_4: string = "";
  domainForwardUrl: string = "";

  //preview agreement
  agreementUrl: string;

  constructor(
    private changeDetect: ChangeDetectorRef,
    private alertService: AlertService,
    private agreementPaymentService: AgreementPaymentService,
    private router: Router,
    private registrationService: RegistrationService
  ) {}

  ngOnInit() {
    this.calculateTotalAmountDue();
    this.insertAgreementFrame();
  }

  setAgreementUrl() {
    //todo: use the best of the 2 following options:
    //<!--src="https://drive.google.com/viewerng/viewer?embedded=true&url=http://devapi.fastbraces.com/web_services/agreement/sample/A/27uld2ahe4s0gomfnqhh"-->
    //<!--src="http://devapi.fastbraces.com/web_services/agreement/sample/A/27uld2ahe4s0gomfnqhh#toolbar=0&navpanes=0&scrollbar=0">-->

    this.agreementUrl =
      environment.apiUrl +
      "agreement/sample/" +
      this.registration.country_agreement_letter +
      "/" +
      this.registration.edit_token +
      "/#toolbar=0&navpanes=0&scrollbar=0";
  }

  onRightClick(event) {
    return false;
  }

  insertAgreementFrame() {
    this.setAgreementUrl();
    var agreementIframe = document.createElement("iframe");
    agreementIframe.setAttribute("class", "agreement-frame-in-cc-form");
    agreementIframe.setAttribute("frameborder", "0");
    //agreementIframe.setAttribute("oncontextmenu", "onRightClick($event)");
    //agreementIframe.setAttribute("contextmenu", "onRightClick($event)");
    agreementIframe.setAttribute("src", this.agreementUrl);
    document.getElementById("add-iframe-here").appendChild(agreementIframe);
  }

  ngAfterViewInit() {
    this.card = elements.create("card");
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener("change", this.cardHandler);
  }

  /**
   * Calculates and sets the total amount due for the payment
   */
  calculateTotalAmountDue(): void {
    this.totalAmountDue =
      this.registration.country_agreement_letter == "A" ? 95 : 29;
    this.domainCostMonthly =
      this.registration.country_agreement_letter == "A" ? 58 : 28;
  }

  /**
   * We need to call the destroy API here in order to avoid attempting to add a duplicate card element if payment is
   * declined
   */
  ngOnDestroy() {
    this.card.removeEventListener("change", this.cardHandler);
    this.card.destroy();
  }

  /**
   * Displays any error that stripe elements may trigger, event listener attached
   * to check for errors
   * @param error
   */
  onChange(error): void {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.changeDetect.detectChanges();
  }

  /**
   * @param form
   *
   * Send CC information to stripe and create a stripe customer to be used as provider's Stripe account.
   * The backend then should initiate the on-boarding process and mark this doctor registration's status as 'agreement-signed'.
   */
  async createCardCustomer(form: NgForm) {
    this.sendingEvent.emit("It is sending. Wait now.");

    /** Testing GUI  */
    /*
        await new Promise(resolve => setTimeout(resolve, 5000));
        this.alertService.success('Thank you, you should receive a copy of your agreement in your email blablabla 3 business days', true);
        this.router.navigate(['']);
        */

    const { token, error } = await stripe.createToken(this.card);
    if (error) {
      this.alertService.error(error.message);
      this.sending = false;
    } else {
      let payment = this.setAgreementPaymentData(token);

      if ((this.registration.courses_id != -10) && (this.registration.courses_id != -11)) {
        this.signNewAgreement(payment);
      } else {
        // With payment for course type -10, without payment for course type -11:
        this.signReinstatedAgreement(payment);
      }
    }
  }

  private signNewAgreement(payment) {
    this.agreementPaymentService.signWithPayment(payment).subscribe(
      (response) => {
        this.alertService.info(
          "Thank you for signing up for the Fastbraces® Technologies Online Training Program. You have received a separate email with a link to create your password at our online portal, https://fastbracesonline.com. The first step is to create a password and login. Once you are logged in, please follow the instructions on the email or call our New Doctor Integration team specialists at ‭(972) 468-9285‬ so we can walk you through the website, answer your questions and help you get started with the online training program.",
          true
        );
        this.router.navigate([""]);
      },
      (error) => {
        // this.alertService.error(error.error[0], true);
        // this.router.navigate([""]);
        // console.log(error.error);
        if (typeof error.error === "string") {
          this.alertService.error(error.error, true);
        } else if (typeof error.error[0] === "string") {
          this.alertService.error(error.error[0], true);
        } else {
          /* Probably timed out.*/
          // tslint:disable-next-line:max-line-length
          /* this.alertService.error("Communication with the server was interrupted. Please try again. Please contact us if you continue experiencing the problem (click \"Contact Us\" link at the bottom of this page).", true); */
          this.alertService.error(
            'Communication with the server was interrupted. Please try again. Please contact us ("Contact Us" link at the bottom of this page) if you continue experiencing the problem.',
            true
          );
        }
        this.router.navigate([""]);
      }
    );
  }

  private signReinstatedAgreement(payment) {
    this.agreementPaymentService.reinstateWithPayment(payment).subscribe(
      (response) => {
        this.alertService.info(
          "Thank you. Your account is now reinstated. A reset password email has been sent to your email address, so that you can access your account.",
          true
        );
        this.router.navigate([""]);
      },
      (error) => {
        if (typeof error.error === "string") {
          this.alertService.error(error.error, true);
        } else {
          /* Probably timed out.*/
          // tslint:disable-next-line:max-line-length
          /* this.alertService.error("Communication with the server was interrupted. Please try again. Please contact us if you continue experiencing the problem (click \"Contact Us\" link at the bottom of this page).", true); */
          this.alertService.error(
            'Communication with the server was interrupted. Please try again. Please contact us ("Contact Us" link at the bottom of this page) if you continue experiencing the problem.',
            true
          );
        }
        this.router.navigate([""]);
      }
    );
  }

  /**
   * Returns the object we will be posting to the backend to create the charge
   * @param token
   * @returns {{name: string, token: any, email: string, ip: (any|string), amount: number}}
   */
  private setAgreementPaymentData(token): Payment {
    return {
      name: this.registration.dentist_name,
      token: token.id,
      email: this.registration.dentist_email,
      ip: token.client_ip,
      amount: this.totalAmountDue,
      registrationId: this.registration.id,
      requestedDomains: [
        this.requestedDomain_1,
        this.requestedDomain_2,
        this.requestedDomain_3,
        this.requestedDomain_4,
      ],
      domainForwardUrl: this.domainForwardUrl,
    };
  }
}

//again just to force type safety
interface Payment {
  name: string;
  token: string;
  email: string;
  ip: string;
  amount: number;
  registrationId: number;

  /** Sure, domains are not payment, but we send them at the same time */
  requestedDomains: string[];
  domainForwardUrl: string;
}
