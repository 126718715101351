import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-why",
  templateUrl: "./why.component.html",
  styleUrls: ["./why.component.css"],
})
export class WhyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
