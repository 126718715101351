import { Component, OnInit } from "@angular/core";
import { ProviderService } from "../../../service/provider.service";

import { Provider } from "../../../models/provider";

import { Country } from "../../../models/country";
import { CountryService } from "../../../service/country.service";

import { ActivatedRoute } from "@angular/router";
import { AlertService } from "../../../service/alert.service";

import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-providers",
  templateUrl: "./providers.component.html",
  styleUrls: ["./providers.component.css"],
})
export class ProvidersComponent implements OnInit {
  lat: number = 39;
  lng: number = -98;

  base: baseLngAndLat = { lat: 39, lng: -98 };

  providers: Provider[];
  countries: Country[];

  zip: string;
  country: number = 226; /* Initialize country to USA */
  distance: number;
  name: string;

  sending: boolean = false; /* Flag that data sending is in progress */

  validDistances: distance[] = [
    { distance: 5 },
    { distance: 15 },
    { distance: 25 },
    { distance: 50 },
    { distance: 75 },
    { distance: 100 },
  ];

  constructor(
    private providerService: ProviderService,
    private countryService: CountryService,
    private alertService: AlertService,
    private meta: Meta,
    private title: Title
  ) {
    this.title.setTitle("Find an Authorized Provider | Fastbraces");

    this.meta.addTags([
      {
        name: "description",
        content:
          "To find an authorized Fastbraces provider near you, use our Find a Dentist feature. Dentists across the globe are becoming providers daily.",
      },
    ]);
  }

  ngOnInit() {
    this.getCountries();
    this.distance = 100;
  }

  /**
   * Gets providers within entered distance from zip
   */
  getProviders(): void {
    this.sending = true;

    this.providerService
      .getProviders(this.zip, this.country, this.distance)
      .subscribe(
        (provider) => (this.providers = provider),
        (error) => {
          this.sending = false;
          this.alertService.error(
            "No providers found for the entered country and zip code"
          );
          window.scrollTo(0, 0);
          return false;
        },
        () => (this.sending = false)
      );
    this.getBaseCoords();
  }

  /**
   *
   * Returns origin point lng and lat for the enetered address.
   * Used to recenter google map
   */
  getBaseCoords(): void {
    this.providerService
      .getBaseLngAndLat(this.zip, this.country)
      .subscribe((base) => {
        this.base = base;
      });
  }

  /**
   * Should probably make countries its own component, we've called it a lot
   * so far
   */
  getCountries(): void {
    this.countryService
      .getCountries()
      .subscribe((countries) => (this.countries = countries));
  }

  getProviderByName(): void {
    this.sending = true;
    window.scrollTo(100, 300);
    this.providerService
      .getProvidersByName(this.uriEncode(this.name))
      .subscribe(
        (provider) => (this.providers = provider),
        (error) => {
          this.sending = false;
          this.alertService.error(
            "Unable to locate any providers using that name"
          );
          window.scrollTo(0, 0);
          return false;
        },
        () => (this.sending = false)
      );
  }

  /**
   * Determines which logo to display in provider results
   * @param seniorMaster
   * @param master
   * @returns string
   */
  getProviderLogo(seniorMaster: number, master: number): string {
    if (seniorMaster == 1) {
      return "../../assets/images/provider/SMA_provider.png";
    }
    if (master == 1) {
      return "../../assets/images/provider/MA_provider.png";
    }

    return "../../assets/images/provider/fb_logo_provider.png";
  }

  /**
   * URI encode an input string
   */
  uriEncode(inputUri: string): string {
    return encodeURIComponent(inputUri);
  }

  /**
   * @param inputName - input string
   * @returns {string} - string after removing substring starting with '('
   * Remove anything at and after "(" for doctor location URL, and return the resulting string
   */
  removeBrackets(inputName: string): string {
    if (inputName.indexOf("(") !== -1) {
      return inputName.substring(0, inputName.indexOf("("));
    } else {
      return inputName;
    }
  }
}

interface distance {
  distance: number;
}

interface baseLngAndLat {
  lat: number;
  lng: number;
}
