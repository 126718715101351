import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Provider } from "../models/provider";
import { environment } from "../../environments/environment";
import { ModelService } from "./model.service";

@Injectable()
export class ProviderService extends ModelService<Provider> {
  private providerUrl = environment.apiUrl + "provider";

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * GETs Providers from the server located @params distance from user
   * @returns {Observable<Object>}
   */
  getProviders(
    zip?: string,
    country?: number,
    distance?: number
  ): Observable<Provider[]> {
    const url = this.providerUrl + "/" + zip + "/" + country + "/" + distance;
    return this.get(url);
  }

  /**
   * GETs a single provider from the server
   *
   * @param params
   * @returns {Observable<Object>}
   */
  getProvider(params): Observable<Provider> {
    if (params["id"]) {
      return this.get(
        this.providerUrl +
          "/details/" +
          encodeURIComponent(params["name"]) +
          "/" +
          params["id"]
      );
    }
    if (params["name"]) {
      return this.get(
        this.providerUrl + "/link/" + encodeURIComponent(params["name"])
      );
    }
  }

  /**
   * GETs a list of all provider locations based on name
   * @param name
   * @returns {Observable<Object>}
   */
  getProvidersByName(name: string): Observable<Provider[]> {
    const url = this.providerUrl + "/name/" + name;
    return this.get(url);
  }

  /**
   *
   * @param formData
   * @returns {Observable<Object>}
   */
  sendProviderConsultation(formData: any): Observable<any> {
    const url = this.providerUrl + "/com/consultation";
    return this.post(url, formData);
  }

  /**
   * GETS base coordinates of entered address to update google map origin point
   * @param zip
   * @param country
   * @returns {Observable<Object>}
   */
  getBaseLngAndLat(zip?: string, country?: number): Observable<any> {
    const url = this.providerUrl + "/base/coord/" + zip + "/" + country;
    return this.get(url);
  }
}
