import { Seminar } from "./seminar";
import { Registration } from "./registration";
import { PaymentStatus } from "./payment_status";

export class Staff_member {
  constructor(
    public id?: number,
    public seminar_id?: number,
    public registration_id?: number,
    public profession?: string,
    public name?: string,
    public email?: string,
    public agd?: string,
    public license?: string,
    public paid?: PaymentStatus,
    public isOriginalRegister?: boolean,
    public isPrestine?: boolean,
    public justAdded?: boolean
  ) {
    this.isPrestine = true;
  }
}

export interface Staff {
  name: string;
  profession: string;
  license: string;
  registrationId: number;
  email: string;
  seminarId: number;
}
