import { Component, OnInit, Input } from "@angular/core";
import { Registration } from "../../../../models/registration";
import { Staff_member } from "../../../../models/staff_member";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RegistrationService } from "../../../../service/registration.service";
import { AlertService } from "../../../../service/alert.service";
@Component({
  selector: "app-add-staff",
  templateUrl: "./add-staff.component.html",
  styleUrls: ["./add-staff.component.css"],
})
export class AddStaffComponent implements OnInit {
  @Input() registration: Registration;
  staffMembers: Staff_member[];
  selectedMember: Staff_member;
  newStaffMember: Staff = <Staff>{};

  professions: Profession[] = [
    { profession: "Front Desk" },
    { profession: "Dental Assistant" },
    { profession: "Hygienist" },
    { profession: "Office Manager" },
  ];
  constructor(
    private modalService: NgbModal,
    private registrationService: RegistrationService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.getRegistrationStaffMembers();
  }

  /**
   * Get current registration staff members
   */
  getRegistrationStaffMembers(): void {
    this.registrationService
      .getStaffMembers(this.registration.id)
      .subscribe((staffMembers) => {
        this.staffMembers = staffMembers;
      });
  }

  /**
   * Open NgbModal modal
   */
  openModal(content): void {
    this.modalService.open(content);
  }

  /**
   * Send entered information to backend to add a staff member and to update staff members count.
   */
  addStaffMember(): void {
    //set required fields to add a new staff member
    this.newStaffMember.registrationId = this.registration.id;
    this.newStaffMember.seminarId = this.registration.courses_id;

    this.registrationService.addStaffMember(this.newStaffMember).subscribe(
      (staffMember) => {
        this.newStaffMember = staffMember;
      },
      (error) => {
        var errorMessage = "An error occurred, please try again. ";
        if (typeof error.error !== "undefined") {
          errorMessage += error.error;
        }
        this.alertService.error(errorMessage);
        window.scrollTo(0, 0);
      },
      () => {
        //destroy this to avoid the same inputs being populated when attempting to add another
        //staff member
        this.alertService.success("Staff Member added successfully");
        this.newStaffMember = <Staff>{};
        this.getRegistrationStaffMembers();
      }
    );
  }

  /**
   * Send request to backend to delete a staff member and to update staff members count.
   */
  deleteStaffMember(): void {
    this.registrationService
      .deleteStaffMember(this.selectedMember.id, this.registration.id)
      .subscribe(
        () => {
          this.alertService.success("Staff Member deleted successfully");
          this.getRegistrationStaffMembers();
        },
        (error) => {
          var errorMessage = "An error occurred, please try again. ";
          if (typeof error.error !== "undefined") {
            errorMessage += error.error;
          }
          this.alertService.error(errorMessage);
          window.scrollTo(0, 0);
        }
      );
  }

  /**
   * Send entered information to backend to update a staff member
   */
  updateStaffMember(): void {
    this.registrationService
      .putSeminarStaffMember(this.selectedMember)
      .subscribe(
        (staffMember) => {
          this.alertService.success("Staff Member updated successfully");
          this.selectedMember = staffMember;
        },
        (error) => {
          var errorMessage = "An error occurred, please try again. ";
          if (typeof error.error !== "undefined") {
            errorMessage += error.error;
          }
          this.alertService.error(errorMessage);
          window.scrollTo(0, 0);
        }
      );
  }
}

interface Staff {
  name: string;
  profession: string;
  license: string;
  registrationId: number;
  email: string;
  seminarId: number;
}

//for type safety
interface Profession {
  profession: string;
}
