import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ProviderService } from "../../../../service/provider.service";
import { Provider } from "../../../../models/provider";
import { AlertService } from "../../../../service/alert.service";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-view-provider",
  templateUrl: "./view-provider.component.html",
  styleUrls: ["./view-provider.component.css"],
})
export class ViewProviderComponent implements OnInit {
  params: any;
  provider: Provider;
  consultation: Consultation = <Consultation>{};
  youtubeVideoForModal: string;
  sending: boolean = false; /* Flag that data sending is in progress */

  //executiveSpeakers = [3974,382,595,596,2765,1403,1330,95,605,327,421];
  //New list of executive speakers
  executiveSpeakers = [
    327, //'F. Jay','Ohmes'
    421, //'Tony','Cigno'
    605, //'Paul','Zollinger'
    //2765,'Patrick','Assal'
    //3064,'Pramod','Thomas'
    //382,    //'Vijay','Gohil'
    595, //'Stephan','Jansen van Vuuren'
    1330, //'Lan','Tran'
    1403, //'Terry','Wong'
    3974, //'Evangelos (Angelo)','Viazis'
    596, //Melissa, Goddard
  ];

  constructor(
    private route: ActivatedRoute,
    private providerService: ProviderService,
    private alertService: AlertService,
    private router: Router,
    private modalService: NgbModal,
    private meta: Meta,
    private title: Title
  ) {
    this.params = this.route.snapshot.params;

    this.title.setTitle("PROVIDER | Fastbraces");

    this.meta.addTags([
      {
        name: "description",
        content:
          "PROVIDER is an authorized provider of Fastbraces, the quick and effortless way to get straighter teeth in 120 days or less.",
      },
    ]);
  }

  ngOnInit() {
    this.getProvider(this.params);
  }

  getProvider(params): void {
    this.providerService.getProvider(params).subscribe(
      (provider) => (this.provider = provider),
      (error) => {
        this.alertService.error(
          "Unable to locate this provider or this provider no longer offers Fastbraces",
          true
        );
        this.router.navigate(["/providers"]);
      }
    );
  }

  sendConsultation(): void {
    this.sending = true;
    this.consultation.providerEmail = this.provider.email;
    this.consultation.providerFullName =
      this.provider.first_name + " " + this.provider.last_name;

    this.providerService.sendProviderConsultation(this.consultation).subscribe(
      (success) => {
        this.sending = false;
        console.log(success);
        this.alertService.success(
          "This provider has been notified. Thank you."
        );
      },
      (error) => {
        this.sending = false;
        var errorMessage = "Something went wrong, please try again. ";
        if (typeof error.error !== "undefined") {
          errorMessage += error.error;
        }
        this.alertService.error(errorMessage);
      }
    );
  }

  /**
   * Open NgbModal modal to display the video in 'youtubeVideoForModal'
   */
  openModal(content): void {
    this.modalService.open(content, { size: "lg" }).result.then((result) => {});
  }

  /**
   * Set the value of youtube video string 'youtubeVideoForModal'
   */
  setYoutubeVideoForModal(videoString: string): void {
    this.youtubeVideoForModal = null; /** Clear the last used value */

    videoString = this.trimVideoString(videoString); //todo: verify that input is a valid YouTube link

    if (videoString) {
      let videoId = this.retrieveYoutubeVideoId(videoString);
      if (videoId != "") {
        this.youtubeVideoForModal =
          "//www.youtube.com/embed/" + videoId + "?rel=0;&autoplay=1";
        this.insertVideoIntoModal();
      }
    }
  }

  /**
   * Insert youtube iFrame into modal body [we cannot do it through src attribute due to security].
   */
  insertVideoIntoModal(): void {
    var youtubeIframe = document.createElement("iframe");
    youtubeIframe.setAttribute("frameborder", "0");
    youtubeIframe.setAttribute("class", "modal_iframe");
    youtubeIframe.setAttribute("src", this.youtubeVideoForModal);
    document.getElementById("add-iframe-here").appendChild(youtubeIframe);
  }

  /**
   * Remove empty characters and any additional parameters from video string
   */
  trimVideoString(videoString: string): string {
    videoString = videoString.trim();
    let indexOfAnd = videoString.indexOf("&");
    return videoString.substring(
      0,
      indexOfAnd != -1 ? indexOfAnd : videoString.length
    );
  }

  /**
   * Retrieve a YouTube video ID, empty string otherwise
   */
  retrieveYoutubeVideoId(videoString: string): string {
    var expressionMatch =
      /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
    let videoId = expressionMatch.exec(videoString)[1];
    if (videoId.length !== 11) {
      return "";
    }
    return videoId;
  }
}

interface Consultation {
  name: string;
  phone: string;
  email: string;
  providerEmail: string;
  providerFullName: string;
}
