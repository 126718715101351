import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-campus",
  templateUrl: "./campus.component.html",
  styleUrls: ["./campus.component.scss"],
})
export class CampusComponent implements OnInit {
  responsiveOptions = [
    {
      breakpoint: "1099px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "730px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor(private modalService: NgbModal) {
    this.modalService = modalService;
  }

  publication: Array<{ title: string }> = [];
  modalOpen: boolean = false;
  imgUrl: string;
  addImages(): void {
    const imageCount = 46; // Make sure to update this
    const exclude_these_images = [
      11, 13, 14, 16, 17, 18, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      32, 33, 34, 35, 36,
    ];

    for (let i = 1; i <= imageCount; i++) {
      if (exclude_these_images.indexOf(i) !== -1) {
        continue;
      }

      this.publication.push({
        title: "../../assets/images/campus/Campus-" + i + ".png",
      });
    }
  }

  openModal(imgUrl, content): void {
    this.imgUrl = imgUrl;
    this.modalService.open(content).result.then();
  }

  ngOnInit(): void {
    this.addImages();
  }
}
