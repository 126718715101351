import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Registration } from "../models/registration";
import { Staff_member } from "../models/staff_member";
import { environment } from "../../environments/environment";
import { ModelService } from "./model.service";

@Injectable()
export class RegistrationService extends ModelService<Registration> {
  private registrationUrl = environment.apiUrl + "registration";

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   *
   * Adds a registration to registered doctors
   * @param registration
   * @returns {Observable<Registration>}
   */
  addRegistration(registration: Registration): Observable<Registration> {
    return this.post(this.registrationUrl, registration);
  }

  /**
   * @param editToken
   * @returns {Registration}
   */
  getRegistrationByToken(editToken: string): Promise<Registration> {
    const url = this.registrationUrl + "/edit/" + editToken;
    return this.get(url).toPromise();
  }

  /**
   * PUTS a registration edit
   * @param registration
   * @returns {Observable<Registration>}
   */
  putRegistration(registration: Registration): Observable<Registration> {
    const url = this.registrationUrl + "/put";
    return this.put(url, registration);
  }

  /**
   *
   * PUTS a new seminar id and date for the selected
   * doctor
   * @param transferer
   * @returns {Observable<Registration>}
   */
  transferRegistration(transferer: Transfer): Observable<Registration> {
    const url = this.registrationUrl + "/transfer";
    return this.put(url, transferer);
  }

  /**
   * POSTS a new staff member
   * @param staffMember
   * @returns {Observable<Staff_member[]>}
   */
  addStaffMember(staffMember: Staff): Observable<any> {
    const url = this.registrationUrl + "/add/staff_member";
    return this.post(url, staffMember);
  }

  /**
   * GETS staff members from server for corresponding registration id
   * @param registrationID
   * @returns {Observable<Object>}
   */
  getStaffMembers(registrationID: number): Observable<Staff_member[]> {
    const url = this.registrationUrl + "/staff_members/" + registrationID;
    return this.get(url);
  }

  /**
   * PUTS a new staff Member details update
   * @param staffMember
   * @returns {Observable<Object>}
   */
  putSeminarStaffMember(staffMember: Staff_member): Observable<any> {
    const url = this.registrationUrl + "/put/staff_member";
    return this.put(url, staffMember);
  }

  /**
   * DELETES a staff member by staff member id
   * @param staffMemberId
   * @returns {Observable<Object>}
   */
  deleteStaffMember(
    staffMemberId: number,
    registrationId: number
  ): Observable<any> {
    const url =
      this.registrationUrl +
      "/delete/staff_member/" +
      staffMemberId +
      "/" +
      registrationId;
    return this.delete(url);
  }
}

interface Transfer {
  seminarId: number;
  registrationId: number;
}

interface Staff {
  name: string;
  profession: string;
  license: string;
  registrationId: number;
  email: string;
  seminarId: number;
}
