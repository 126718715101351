import { BeforeAfterComponent } from "./pages/before-after/before-after.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { SeminarComponent } from "./pages/seminar/seminar.component";
import { HomeComponent } from "./pages/home/home.component";
import { TestComponent } from "./pages/test/test.component";
import { ProductComponent } from "./pages/product/product.component";
import { PublicationsComponent } from "./pages/publications/publications.component";

import { AgreementComponent } from "./pages/agreement/agreement.component";
import { RegistrationComponent } from "./pages/registration/registration.component";
import { EditRegistrationComponent } from "./pages/registration/edit-registration/edit-registration.component";
import { AddStaffComponent } from "./pages/registration/edit-registration/add-staff/add-staff.component";
import { ProvidersComponent } from "./pages/old_pages/providers/providers.component";

import { BiologyComponent } from "./pages/old_pages/biology/biology.component";
import { WorksComponent } from "./pages/old_pages/works/works.component";
import { WhyComponent } from "./pages/old_pages/why/why.component";

import { SupportComponent } from "./pages/old_pages/support/support.component";
import { HonorsComponent } from "./pages/old_pages/honors/honors.component";
import { CertificatesComponent } from "./pages/certificates/certificates.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { HistoryComponent } from "./pages/old_pages/history/history.component";

import { ViewProviderComponent } from "./pages/old_pages/providers/view-provider/view-provider.component";
import { ImageUploadComponent } from "./pages/registration/edit-registration/image-upload/image-upload.component";
import { TransferRegistrationComponent } from "./pages/old_pages/registration/edit-registration/transfer-registration/transfer-registration.component";
import { SeminarPaymentComponent } from "./pages/old_pages/registration/seminar-payment/seminar-payment.component";
import { ResourceComponent } from "./pages/old_pages/resources/resource/resource.component";
import { RecommendationsComponent } from "./pages/old_pages/recommendations/recommendations.component";
import { ComparisonComponent } from "./pages/old_pages/comparison/comparison.component";

import { CampusComponent } from "./pages/campus/campus.component";
import { MoreCasesComponent } from "./pages/more-cases/more-cases.component";


/** For Joe [no header, no footer provider search]: */
import { SearchProvidersComponent } from "./pages/search-providers/providers.component";
import { ViewSearchedProviderComponent } from "./pages/search-providers/view-provider/view-provider.component";
import { RecentPublicationsComponent } from "./pages/recent-publications/recent-publications.component";
import { MediaComponent } from "./pages/media/media.component";
import {DoctorSupportComponent} from "./pages/doctor-support/doctor-support.component";
import { DentaltownComponent } from "./pages/dentaltown/dentaltown.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "test", component: TestComponent },
  { path: "product", component: ProductComponent },
  { path: "publications", component: PublicationsComponent },
  { path: "before_and_after", component: BeforeAfterComponent },
  { path: "seminars", component: SeminarComponent },
  { path: "seminars/:countryId", component: SeminarComponent },
  // {
  //   path: "orthodontic/list_courses/usa",
  //   component: SeminarsComponent,
  //   data: { countryId: 226 },
  // },
  // {
  //   path: "orthodontic/list_courses/international/:countryId",
  //   component: SeminarsComponent,
  // },
  // { path: "agreement/:token", component: AgreementComponent },
  // Todo:
  { path: "agreement/:token", redirectTo: "signup/:token" },
  { path: "signup/:token", component: AgreementComponent },
  { path: "register/:id", component: RegistrationComponent },
  {
    path: "registration/edit_registration/:token",
    component: EditRegistrationComponent,
  },
  { path: "registration/add_staff", component: AddStaffComponent },
  { path: "registration/image_upload", component: ImageUploadComponent },
  {
    path: "registration/transfer_registration/:editToken",
    component: TransferRegistrationComponent,
  },
  { path: "providers", component: SearchProvidersComponent },
  { path: "providers/error/:error", component: SearchProvidersComponent },
  { path: "biology", component: BiologyComponent },
  // {path: 'how_it_works', component: WorksComponent},    //
  //  { path: "before_and_after", component: BeforeComponent },
  { path: "support", component: SupportComponent },
  {
    path: "providers/view_provider/:name/:id",
    component: ViewSearchedProviderComponent,
  },
  { path: "providers/:name/:id", component: ViewSearchedProviderComponent },
  { path: "providers/:name", component: ViewSearchedProviderComponent },
  { path: "what_dentists_say", component: HonorsComponent },
  { path: "certificates", component: CertificatesComponent },
  { path: "terms", component: TermsComponent },
  { path: "seminar_follow_up", component: ContactComponent },
  { path: "history", component: HistoryComponent },
  { path: "registration/payment", component: SeminarPaymentComponent },
  { path: "research_and_technology", component: BiologyComponent },
  { path: "resources", component: ResourceComponent },
  { path: "recommendations", component: RecommendationsComponent },
  { path: "campus", component: CampusComponent },
  { path: "more-cases", component: MoreCasesComponent },
  // { path: "search", component: SearchProvidersComponent },
  // {
  //   path: "search/view_provider/:name/:id",
  //   component: ViewSearchedProviderComponent,
  // },
  // { path: "search/:name/:id", component: ViewSearchedProviderComponent },
  // { path: "search/:name", component: ViewSearchedProviderComponent },
  { path: "why", component: WhyComponent },
  { path: "recent-publications", component: RecentPublicationsComponent },
  { path: "media", component: MediaComponent },
  { path: "news", component: MediaComponent },
  { path: "doctor-support", component: DoctorSupportComponent },
  { path: "dentaltown", component: DentaltownComponent },
  { path: "**", component: HomeComponent },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
