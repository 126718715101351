// Mostly copied from and modeled after "recent-publications" component and "recent-list" component.
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-media",
  templateUrl: "./media.component.html",
  styleUrls: ["./media.component.scss"],
})
export class MediaComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}

  // goToPubPage() {
  //   this.router.navigateByUrl("/publications");
  // }
  openLinkInNewWindow(link: string): void {
    window.open(link, "newwindow");
  }

  goToDentaltown() {
    this.router.navigateByUrl("/dentaltown");
  }
}
