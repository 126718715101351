import {
  Component,
  Input,
  forwardRef,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { KeyValuePairModel } from "./models/keyvaluepair.model";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent {
  @Input() id: string;
  @Input() name: string;
  @Input() label: string = "Default Label";
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() value: KeyValuePairModel;
  @Input() options: Array<KeyValuePairModel> = new Array<KeyValuePairModel>();
  @Output() valueChange = new EventEmitter<KeyValuePairModel>();

  @Input() errorMessage: string;
  valid: boolean = true;
  pristine: boolean = true;
  hasFocus: boolean = false;

  constructor() {}

  onChange(item: KeyValuePairModel) {
    this.value = item;
    this.valueChange.emit(item);
  }
}
