import { MtxSelectComponent } from "./../../components/mtx-select/mtx-select.component";
import { map } from "rxjs/operators";
import {
  Component,
  OnInit,
  Input,
  Inject,
  ViewChild,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { SeminarService } from "../../service/seminar.service";
import { Seminar } from "../../models/seminar";
import { ActivatedRoute } from "@angular/router";
import { IRegistrationObject, Registration } from "../../models/registration";
import { Country } from "../../models/country";
import { AlertService } from "../../service";
import { Router } from "@angular/router";
import { RegistrationService } from "../../service/registration.service";
import { CountryService } from "../../service/country.service";
import { FacebookService, InitParams } from "ngx-facebook";
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { WindowRef } from "app/injectables/window-ref";
import { DOCUMENT } from "@angular/common";
import { MtxInputComponent } from "app/components/mtx-input/mtx-input.component";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit {
  @Input() seminar: Seminar;

  @ViewChildren(MtxInputComponent)
  inputComponents: QueryList<MtxInputComponent>;

  @ViewChildren(MtxSelectComponent)
  selectComponents: QueryList<MtxSelectComponent>;

  registration: Registration;
  registrationObject: IRegistrationObject[];
  countries: Country[];
  countryArray: string[] = [];
  countrySelected: string;

  canRegistration: boolean = true; //will determine if we are showing the registration form or the payment form
  showPayment: boolean = false;
  sending: boolean = false;
  shareUrl: string = window.location.href; //Url to share on LinkedIn/Facebook
  seminarMetaData: string = "Loading...";
  isSpanish: boolean;
  ranchCourse: boolean = false;
  showRegisteredMessage: boolean = false;
  errorRegistering: boolean = undefined;
  registrationInputError: boolean = undefined;
  registrationErrorMessage: string = "";
  loading: boolean = false;

  professions: string[] = [
    "General Dentist",
    "Orthodontist",
    "Dental Hygienist",
    "Staff Member",
    "Pedodontist",
    "Prosthodontist",
    "Periodontist",
    "Oral Surgeon",
    "Endodontist",
  ];

  /*
  referrals: string[] = [
    "Digital Ad",
    "Doctor Referral",
    "Email",
    "Facebook",
    // "Google",
    "Instagram",
    "Internet Search",
    "LinkedIn",
    "Magazine",
    // "Chrisad",
    // "Phone Call",
    "Postcard",
    "Press Release",
    "Twitter",
    "YouTube",
  ];
  */

  referrals: string[] = [
    "Social Media",
    "Dentaltown Magazine",
    "The Profitable Dentist",
    "Doctor Referral",
    "Email",
    "Postcard",
    "Letter",
    // "Text Message",
    // "FedEx Letter",
    "Internet Search",
    // "JCO Magazine",
    // "Letter",
    // "Orthotown Magazine",
    // "TikTok",
    "Chrisad",
  ];

  owners: Owner[] = [{ status: "Yes" }, { status: "No" }];

  constructor(
    private seminarService: SeminarService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private router: Router,
    private registrationService: RegistrationService,
    private countryService: CountryService,
    private facebookService: FacebookService,
    private seoTitle: Title,
    private seoMeta: Meta,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    //instantiate a new registration model to bind to our form
    //probably a better way to do this
    this.registration = new Registration();
    this.registrationObject = this.registration.getRegistrationObject();
    this.registration.staff_attending = 0;

    this.countryService.getCountries().subscribe((countries) => {
      this.countryArray = countries.map((country: Country) => {
        return country.name;
      });

      this.countries = countries;

      this.document.body.scrollTop = 0;
    });

    //required to utitlize fb module
    let initParams: InitParams = {
      xfbml: true,
      version: "v2.9",
    };
    //init facebookservice
    this.facebookService.init(initParams);
    this.getSeminar();
  }

  /**
   * Gets needed data for seminar doctor is registering for
   */
  getSeminar(): void {
    const id = +this.route.snapshot.paramMap.get("id");
    this.seminarService
      .getSeminarById(id)
      .then((seminar) => this.seminarCallback(seminar));
  }

  /**
   * Callback method for promise, sets seminar memeber
   * as well as seminarMetaData member and finally
   * sets the title to be used for sharing
   * @param seminar
   */
  private seminarCallback(seminar: any): void {
    this.checkSeminar(seminar);
    this.seminar = seminar;
    this.seminarMetaData =
      this.seminar.city +
      ", " +
      this.seminar.state +
      " on " +
      this.seminar.from_date;
    this.seoTitle.setTitle(
      "Can teeth really be straightened properly and without extractions in about 100 days?" +
        "Come find out at the FREE One-day Fastbraces® Technologies seminar in " +
        this.seminarMetaData
    );
    this.seoMeta.addTag({
      name: "og:description",
      content:
        "Both dentists & orthodontists are using Fastbraces® Technology. Now offered in over 50 countries!",
    });

    if (
      this.seminar.country_id == 138 ||
      this.seminar.country_id == 199 ||
      this.seminar.country_id == 43 ||
      this.seminar.country_id == 47
    ) {
      this.isSpanish = true;
      this.translate.setDefaultLang("es");
    }

    if (this.seminar.deleted == 1) {
      this.alertService.success(
        "Couldn't fetch specified seminar. Please try another seminar.",
        true
      );
      this.router.navigate(["/seminars"]);
    }

    /** If the course is on the ranch, show range image and text: */
    if (this.seminar.city.indexOf("Red Oak") != -1) {
      this.ranchCourse = true;
    }
  }

  /**
   * @param seminar
   * Is this seminar available?
   */
  private checkSeminar(seminar) {
    var date = new Date();
    var nowDate = date.toISOString().substr(0, 10);
    if (seminar === null || seminar.from_date < nowDate) {
      this.router.navigate(["/"]);
    }
  }

  setCountry(): void {
    if (this.countrySelected) {
      const country = this.countries.find(
        (i) => i.name === this.countrySelected
      );
      this.registration.country = country.country_id;
      this.registration.country_id = country.country_id.toString();
    }
  }

  /**
   * Calls to POST registration data to server
   * and then determines if payment is required
   */
  createRegistration(): void {
    console.log(this.registrationObject);

    this.setCountry();

    this.inputComponents.forEach((input) => {
      if (input && input.required && input.required === true) {
        input.checkValue();
      }
    });

    this.selectComponents.forEach((select) => {
      select.checkValue();
    });

    let registrationError = this.registration.validateInput();

    if (registrationError !== "") {
      this.registrationInputError = true;
      this.registrationErrorMessage = registrationError;
      return;
    } else {
      this.registrationInputError = false;
      this.registrationErrorMessage = "";
    }

    this.loading = true;
    //set the values that are not user input
    this.setRegistrationData();
    this.sending = true;
    //the response of a successful registration will replace the registration object with the results from the server,
    //so the staff attending field will be entered value + 1
    let initialStaffAttending: number = this.registration.staff_attending;

    this.registrationService.addRegistration(this.registration).subscribe(
      (success) => {
        this.registration = success;
        this.errorRegistering = false;
        this.showRegisteredMessage = true;
        this.loading = false;
        this.sending = false;

        if (success.registered_status_id == 2) {
          this.router.navigate(["agreement/" + success.edit_token]);
        }
      },
      (error) => {
        this.sending = false;
        this.errorRegistering = true;
        this.showRegisteredMessage = true;
        this.loading = false;
        this.sending = false;
        return false;
      }
    );
  }

  /**
   * Sets needed registration data not provided by client
   */
  private setRegistrationData(): void {
    this.registration.courses_id = this.seminar.id;
    this.registration.date_attending = this.seminar.date;
    this.registration.dentist_name =
      this.registration.first_name + " " + this.registration.last_name;
  }

  public editForm() {
    if (this.errorRegistering) {
      this.errorRegistering = undefined;
      this.showRegisteredMessage = false;
    } else {
      window.scrollTo(0, 0);
      this.router.navigate([
        "/registration/edit_registration/" + this.registration.edit_token,
      ]);
    }
  }

  /**
   * Facebook servers are out of sync and slow to update, so using a custom value 'CustomerRegistrationRegComponent' event for now to avoid confusion.
   */
  public callFBCustomerRegistration() {
    fbq("trackCustom", "CustomerRegistration", {
      // Add required parameters, make sure that they are not _removed_ from pixel.
      // custom_param1: 'ABCD', custom_param2: 1234, value: 10.00, currency: 'USD',
    });
  }

  /**
   * Call LinkedIn pixel "https://px.ads.linkedin.com/collect/?pid=2054825&conversionId=2026577&fmt=gif"
   */
  public linkedInClickedRegister() {
    var img = document.createElement("img");
    img.setAttribute(
      "src",
      "https://px.ads.linkedin.com/collect/?pid=1276384&conversionId=4478587&fmt=gif"
    );
    document.body.appendChild(img);
  }

  /**
   * Call Belmont pixel "https://insight.adsrvr.org/track/pxl/?adv=tybeo8i&ct=0:tsm0xru&fmt=3"
   */
  /* was called by "submit_registration" button:*/
  public belmontClickedRegister() {
    var img_b = document.createElement("img");
    img_b.setAttribute("height", "1");
    img_b.setAttribute("width", "1");
    img_b.setAttribute(
      "src",
      "https://insight.adsrvr.org/track/pxl/?adv=tybeo8i&ct=0:tsm0xru&fmt=3"
    );
    document.body.appendChild(img_b);
  }
}

// for type safety
interface Profession {
  profession: string;
}
interface Referral {
  source: string;
}
interface Owner {
  status: string;
}
