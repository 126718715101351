import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() isLocked: boolean;
  @Input() yScrollPosition: Number;
  @Input() solidAtY: Number;
  @Input() isTransparent: Boolean = false;
  @Input() isOpen: Boolean = false;
  @Input() isSmall: Boolean = false;
  @Input() currentUrl: string = "";

  gTranslate: any = null;

  constructor(public router: Router, private elementRef: ElementRef) {
    router.events.subscribe((event) => {
      this.close();
    });
  }

  _getParent(): HTMLElement {
    const element: HTMLElement = this.elementRef.nativeElement;
    return element.parentElement.parentElement;
  }

  openLogin(): void {
    window.location.replace("https://www.fastbracesonline.com");
  }

  ngOnInit(): void {
    this._getParent().addEventListener("scroll", () => this._handleScroll());
    window.addEventListener("resize", () => this._handleResize());
    this._handleScroll();
    this._handleResize();
  }

  _handleResize(): void {
    this.isSmall = this._getParent().clientWidth <= 1340;

    setTimeout(() => {
      // @ts-ignore
      // tslint:disable-next-line:no-unused-expression
      new google.translate.TranslateElement(
        {
          pageLanguage: "en",

          layout: this.isSmall
            ? // @ts-ignore
              // google.translate.TranslateElement.InlineLayout.SIMPLE
              google.translate.TranslateElement.InlineLayout.HORIZONTAL
            : // @ts-ignore
              google.translate.TranslateElement.InlineLayout.VERTICAL,
        }, // SIMPLE, VERTICAL, HORIZONTAL
        "google_translate_element"
      );
    }, 1000);
  }

  _handleScroll(): void {
    this.yScrollPosition = this._getParent().scrollTop;

    if (this._getParent().scrollTop >= this.solidAtY) {
      this.isTransparent = false;
    } else {
      this.isTransparent = true;
    }
    if (this.isOpen) {
      this.isTransparent = false;
    }
  }

  toggleOpen(): void {
    this.isOpen = !this.isOpen;
    this._handleScroll();
  }

  close(): void {
    this.isOpen = false;
    this._handleScroll();
  }
}
