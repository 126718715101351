import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-material-select",
  templateUrl: "./mtx-select.component.html",
  styleUrls: ["./mtx-select.component.scss"],
  host: {
    "(document:click)": "onClick($event)",
  },
})
export class MtxSelectComponent implements OnInit {
  constructor(private elem: ElementRef, private renderer: Renderer2) {}
  @Input() label: string;
  @Input() inputValue: string;
  @Input() errorMessage: string;
  @Output() inputModelChange = new EventEmitter<string>();

  @Input()
  get inputModel() {
    return this.inputValue;
  }

  set inputModel(val) {
    this.inputValue = val;

    if (this.inputValue) {
      const element = this.elem.nativeElement.querySelector(".form-field");
      this.renderer.addClass(element, "form-field--is-filled");

      this.dropdownList.forEach((x) => {
        if (this.inputValue && this.inputValue === x.text) {
          x.selected = true;
        }
      });
    }
  }

  @Input() dropdownItems: Array<string>;
  @Input() type: string;

  public componentId: number;
  public showDropDown: boolean = false;
  public dropdownList: Array<{
    text: string;
    selected: boolean;
    searchValue: string;
  }> = [];
  public originalList: Array<{
    text: string;
    selected: boolean;
    searchValue: string;
  }> = [];
  public hasError: boolean;

  private _data = new BehaviorSubject<[]>([]);
  @Input()
  set data(value) {
    // set the latest value for _data BehaviorSubject
    this._data.next(value);
  }

  get data() {
    // get the latest value from _data BehaviorSubject
    return this._data.getValue();
  }

  checkValue() {
    if (!this.inputValue || this.inputValue === "") {
      this.hasError = true;
    }
  }

  filterList(event) {
    this.dropdownList = this.originalList.map((x) => Object.assign({}, x));
    var search = (event as string).toUpperCase();
    this.dropdownList = this.dropdownList.filter((i) =>
      i.searchValue.startsWith(search)
    );

    if (this.inputValue) {
      this.markSelected(null);
      this.dropdownList = this.originalList.map((x) => Object.assign({}, x));
      this.showDropDown = true;
    }
  }

  ngOnInit(): void {
    if (this.type && this.type.toLocaleLowerCase() === "boolean") {
      this.dropdownList = [
        {
          text: "Yes",
          selected: false,
          searchValue: "YES",
        },
        {
          text: "No",
          selected: false,
          searchValue: "YES",
        },
      ];
      this.originalList = this.dropdownList.map((x) => Object.assign({}, x));
    } else {
      this._data.subscribe((x) => {
        this.dropdownList = [];
        if (x) {
          x.forEach((item) => {
            this.dropdownList.push({
              text: item,
              selected: false,
              searchValue: (item as string).toUpperCase(),
            });
          });
        }
        this.originalList = this.dropdownList.map((x) => Object.assign({}, x));
      });
    }

    if (this.inputValue) {
      const element = this.elem.nativeElement.querySelector(".form-field");
      this.renderer.addClass(element, "form-field--is-filled");
    }

    this.componentId = Math.floor(Math.random() * 1000000000);
  }

  onClick(event: MouseEvent) {
    const selector = "form-field__input_" + this.componentId;
    const containerSelector = "form-field_" + this.componentId;
    const clickedEle = event.target as HTMLElement;

    if (
      this.showDropDown &&
      clickedEle.id !== selector &&
      clickedEle.id !== containerSelector
    ) {
      this.showDropDown = false;
      if (!this.inputValue) {
        const formLabel = `.form-field`;
        const element = this.elem.nativeElement.querySelector(formLabel);
        this.renderer.removeClass(element, "form-field--is-filled");
        this.hasError = true;
      }
    }
  }

  focusInput(id) {
    var fieldId = "form-field__input_" + id;
    document.getElementById(fieldId).focus();
    console.log("focused");
  }

  toggleDropdownOn(): void {
    this.showDropDown = true;
  }

  markSelected(i): void {
    this.hasError = false;
    const previouslySelected = this.dropdownList.find(
      (value) => value.selected === true
    );
    if (previouslySelected) {
      previouslySelected.selected = false;
    }
    if (i != null) {
      this.dropdownList[i].selected = true;
      this.inputValue = this.dropdownList[i].text;
      this.inputModelChange.emit(this.inputValue);
    } else {
      this.inputValue = undefined;
      this.inputModelChange.emit(this.inputValue);
    }
  }

  setInputActive(el, active, eventType): void {
    const formField = el.target.parentNode.parentNode;
    if (active) {
      formField.classList.add("form-field--is-active");
    } else {
      formField.classList.remove("form-field--is-active");
      el.target.value === ""
        ? formField.classList.remove("form-field--is-filled")
        : formField.classList.add("form-field--is-filled");
    }
  }
}
