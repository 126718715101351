import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-videos-list",
  templateUrl: "./videos-list.component.html",
  styleUrls: ["./videos-list.component.scss"],
})
export class VideosListComponent implements OnInit {
  constructor(private modalService: NgbModal) {
    this.modalService = modalService;
  }

  ngOnInit(): void {}

  openInNewTab(url): void {
    window.open(url, "_blank");
  }

  openLinkInNewWindow(url): void {
    window.open(url, "newwindow");
  }

  playVideoFromList(video, image): void {
    window.open(video, "newwindow");
  }

  // openInNewTab(url): void {
  //   window.open(url, "_blank");
  // }

  // openLinkInNewWindow(url): void {
  //   window.open(url, "newwindow");
  // }

  // playVideoFromList(video, image): void {
  // window.open(video, "newwindow");
  // }

  videosList: Array<{
    title?: string;
    bgColor?: string;
    videoUrl: string;
    isExternalLink?: string;
    bgImg?: string;
  }> = [
     {
      title: "“Fastbraces® Is Horizontal Periodontics” - Dr. Kendal Major, Periodontist",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl: "https://d2hhfkww9n0hw5.cloudfront.net/videos/Dr_Kendal_Major_Partnership_between_Ortho_&_Perio_1.m4v",
      isExternalLink: "true",
      bgImg: "assets/images/media/videos_screenshots/game_changer.png",
    },
    {
      title: "The Wellness Report",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl: "https://d2hhfkww9n0hw5.cloudfront.net/videos/Example3_nr.m4v",
      isExternalLink: "true",
      bgImg: "assets/images/media/videos_screenshots/Example3_nr.png",
    },
    {
      title: "CBS News",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl: "https://d2hhfkww9n0hw5.cloudfront.net/videos/Example1_nr.m4v",
      isExternalLink: "true",
      bgImg: "assets/images/media/videos_screenshots/Example1_nr.png",
    },
    {
      title: "Fox Scene",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl: "https://d2hhfkww9n0hw5.cloudfront.net/videos/Example2_nr.m4v",
      isExternalLink: "true",
      bgImg: "assets/images/media/videos_screenshots/Example2_nr.png",
    },

    {
      title: "The Morning Blend",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl:
        "https://d2hhfkww9n0hw5.cloudfront.net/videos/Safe_Affordable_Way_to_Straighten_Teeth_Fast.m4v",
      isExternalLink: "Yahoo News",
      bgImg: "assets/images/media/videos_screenshots/safe_affordable.png",
    },
    {
      title: "The Wellness Hour",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl: "https://d2hhfkww9n0hw5.cloudfront.net/videos/Example1_dti.m4v",
      isExternalLink: "true",
      bgImg: "assets/images/media/videos_screenshots/safe_affordable.png",
    },
    {
      title: "Great Day St. Louis",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl: "https://d2hhfkww9n0hw5.cloudfront.net/videos/Example2_dti.m4v",
      isExternalLink: "true",
      bgImg: "assets/images/media/videos_screenshots/Example2_dti.png",
    },

    {
      title: "Pacific Dental and Fastbraces®",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl: "https://d2hhfkww9n0hw5.cloudfront.net/videos/Example3_dta.m4v",
      isExternalLink: "true",
      bgImg: "assets/images/media/videos_screenshots/Example3_dta.png",
    },
    {
      title: "The Morning Blend",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl:
        "https://d2hhfkww9n0hw5.cloudfront.net/videos/Tony_Cigno_TURBO_Morning_BLEND.m4v",
      isExternalLink: "true",
      bgImg: "assets/images/media/videos_screenshots/Tony_Cigno.png",
    },
    {
      title: "The History of Fastbraces® Through TV Reports",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl:
        "https://d2hhfkww9n0hw5.cloudfront.net/videos/Fastbraces_in_the_NEWS.m4v",
      isExternalLink: "true",
      bgImg: "assets/images/media/videos_screenshots/Example4_nr.png",
    },
    // Bulgarian video:
    {
      title: "Bulgarian Television",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl:
        "https://d2hhfkww9n0hw5.cloudfront.net/videos/DrDimoDecember2022.m4v",
      isExternalLink: "true",
      bgImg: "assets/images/media/videos_screenshots/DrDimoDimovDec2022.png",
    },
    {
      title: "Cyprus Television",
      bgColor: "rgba(71, 47, 145, 0.8)",
      videoUrl:
        "https://d2hhfkww9n0hw5.cloudfront.net/videos/Η_ΟΡΘΟΔΟΝΤΙΚΗ_ΘΕΡΑΠΕΙΑ_ΤΕΧΝΟΛΟΓΙΑ.m4v",
      isExternalLink: "true",
      bgImg:
        "assets/images/media/videos_screenshots/Η_ΟΡΘΟΔΟΝΤΙΚΗ_ΘΕΡΑΠΕΙΑ_ΤΕΧΝΟΛΟΓΙΑ.png",
    },
    //bgImg: "assets/images/campus/Rex.png",
  ];

  /*
  Original format from media links.

  videosList: Array<{ title: string; publishDate: string; link: string, newspaper_name?: string}> = [
    {
      title: "Annual Global Business Conference to be held in Dallas on the Revolutionary Fastbraces® Orthodontic Technology",
      publishDate: "September 19, 2022",
      link: "https://apnews.com/article/technology-health-texas-dallas-23af29a487149d7210ebdb9b12c38022",
      newspaper_name: "AP News",
    },
    {
      title: "Orthodontic Influencer, Dr. Evangelos Viazis, Talks on the Revolutionary Fastbraces Orthodontic Technology",
      publishDate: "September 9, 2022",
      link: "https://www.yahoo.com/now/orthodontic-influencer-dr-evangelos-viazis-231735856.html",
      newspaper_name: "Yahoo News",
    },
    {
      title: "Orthodontic Influencer, Dr. Evangelos Viazis, Talks on the Revolutionary Fastbraces Orthodontic Technology",
      publishDate: "September 9, 2022",
      link: "https://apnews.com/press-release/newmediawire/technology-health-texas-dallas-23deac91e320cf194ceabdbf144e3b70",
      newspaper_name: "AP News",
    },

  ];
  */
}
